import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrain,
  faArrowUpRight,
  faListTree,
} from "@fortawesome/pro-regular-svg-icons";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { useState, useEffect } from "react";
import useFetchFunctions from "../../../services/trips";
import {
  defaultOccurrencePayload,
  defaultLinkedOccurrencePayload,
} from "./constants";
import { useQueryClient, useQueries } from "@tanstack/react-query";
import { findFirstAndLastVehicles } from "../../../utils/helperFunctions";
import Checkbox from "../../Checkbox";
import NumberField from "../../NumberField";
import SingleSelect from "../../SingleSelect";
import TextArea from "../../TextArea";
import DynamicFormFields from "./DynamicFormFields";
import { STATIC_CAUSES } from "./constants";
import LinkedTripOccurrenceCard from "./LinkedTripOccurrenceCard";
import DynamicOccurrenceCauseFormFields from "./DynamicOccurrenceCauseFormFields";
import WaiverStatusForm from "./WaiverStatusForm";
import toast from "react-hot-toast";
import { FormatGroupLabel } from "./utils";
import TextField from "../../TextField";

export default function TripOccurrenceFlyout({
  operator_short_name,
  service_date,
  trip,
  occurrenceDrawer,
  setOccurrenceDrawer,
  occurrenceToBeEditedProp,
  setOccurrenceToBeEditedProp,
}) {
  const { firstVehicle, lastVehicle } = findFirstAndLastVehicles(
    trip?.assigned_vehicle_formation?.vehicle_formation_vehicles
  );

  //loading states
  const [postingOccurrence, setPostingOccurrence] = useState(false);
  const [linkingOccurrence, setLinkingOccurrence] = useState(false);
  const [searchingVehicle, setSearchingVehicle] = useState(false);

  //form states
  const [occurrencePayload, setOccurrencePayload] = useState(
    defaultOccurrencePayload
  );
  const [linkedTripOccurrence, setLinkedTripOccurrence] = useState(
    defaultLinkedOccurrencePayload
  );
  const [
    operatorTripOccurrenceFieldsPayload,
    setOperatorTripOccurrenceFieldsPayload,
  ] = useState({});
  const [occurrenceCauseValuesPayload, setOccurrenceCauseValuesPayload] = useState(
    {}
  );

  //edit mode form states
  const [originalValues, setOriginalValues] = useState({});
  const [originalOccurrenceCauseValues, setOriginalOccurrenceCauseValues] = useState(
    {}
  );
  const [
    originalOperatorTripOccurrenceFieldValues,
    setOriginalOperatorTripOccurrenceFieldValues,
  ] = useState({});
  //
  const [occurrenceToBeEdited, setOccurrenceToBeEdited] = useState({});
  console.log("🚀 ~ occurrenceToBeEdited:", occurrenceToBeEdited);
  const [linkedTripOccurrenceToBeEdited, setLinkedTripOccurrenceToBeEdited] =
    useState({});
  const [
    operatorTripOccurrenceFieldValuesForEdit,
    setOperatorTripOccurrenceFieldValuesForEdit,
  ] = useState({});
  const [occurrenceCauseValuesForEdit, setOccurrenceCauseValuesForEdit] = useState(
    {}
  );
  const [loadingEditState, setLoadingEditState] = useState(false);

  //other visual states
  const [tripOccurrenceType, setTripOccurrenceType] = useState("new");
  const [isEditMode, setIsEditMode] = useState(false);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState({
    carrier_code: "",
    serial_number: "",
  });

  const resetFormState = () => {
    setOccurrencePayload(defaultOccurrencePayload);
    setVehicleSearchPayload({ carrier_code: "", serial_number: "" });
    setOccurrenceCauseFields([]);
    setOccurrenceCauseValuesPayload({});
    setOperatorTripOccurrenceFieldsPayload({});
    setOperatorTripOccurrenceFieldValuesForEdit({});
    setOccurrenceCauseValuesForEdit({});
    setLinkedTripOccurrence(defaultLinkedOccurrencePayload);
    setIsEditMode(false);
    setLinkedTripOccurrenceToBeEdited({});
    setOccurrenceToBeEdited({});
    setOccurrenceToBeEditedProp(null);
  };

  const {
    fetchOperatorTripOccurrenceFields,
    fetchResponsibleParties,
    fetchOperatorOccurrenceCauses,
    fetchLinearAssets,
    fetchStationaryAssets,
    fetchVehicle,
    postTripOccurrence,
    searchLinkToExisting,
    editTripOccurrence,
  } = useFetchFunctions();

  const queryClient = useQueryClient();

  const occurrenceSearchPayload = {
    from_service_date: service_date,
    to_service_date: service_date,
    search_type: "link_to_existing",
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["operator_trip_occurrence_fields", operator_short_name],
        queryFn: () => fetchOperatorTripOccurrenceFields(operator_short_name),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: ["operator_occurrence_causes", operator_short_name],
        queryFn: () => fetchOperatorOccurrenceCauses(operator_short_name),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: [
          "stationary_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
        ],
        queryFn: () =>
          fetchStationaryAssets(
            operator_short_name,
            service_date,
            trip?.trip_name,
            occurrencePayload.operator_trip_occurrence_cause_id.value
              .stationary_asset_type
          ),
        staleTime: 1000 * 10,
        enabled:
          !!occurrencePayload?.operator_trip_occurrence_cause_id?.value
            ?.stationary_asset_type,
      },
      {
        queryKey: [
          "occurrences_operator_service_day",
          operator_short_name,
          service_date,
        ],
        queryFn: () => searchLinkToExisting(occurrenceSearchPayload),
        staleTime: 1000 * 10,
        enabled: tripOccurrenceType === "linked",
      },
      {
        queryKey: [
          "linear_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
        ],
        queryFn: () =>
          fetchLinearAssets(operator_short_name, service_date, trip?.trip_name),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
    ],
  });

  const operatorTripOccurrenceFields = results[0];
  const responsibleParties = results[1];
  const operatorOccurrenceCauses = results[2];
  const stationaryAssets = results[3];
  const occurrencesOperatorServiceDay = results[4];
  const linearAssets = results[5];

  useEffect(() => {
    const anyLoading = results.some((result) => result.isLoading);
    setLoadingEditState(anyLoading);
  }, [results]);

  useEffect(() => {
    if (
      operatorTripOccurrenceFields.data &&
      !operatorTripOccurrenceFields.isLoading
    ) {
      const operatorOccurrencePayloadFormat = {};
      operatorTripOccurrenceFields.data.forEach((field) => {
        operatorOccurrencePayloadFormat[field.trip_occurrence_field.name] = "";
      });
      setOperatorTripOccurrenceFieldsPayload(operatorOccurrencePayloadFormat);
    }
  }, [operatorTripOccurrenceFields.data, operatorTripOccurrenceFields.isLoading]);

  const isSaveDisabled = () => {
    if (!isEditMode) return false;

    if (tripOccurrenceType === "new") {
      return (
        Object.keys(occurrenceToBeEdited).length === 0 &&
        Object.keys(operatorTripOccurrenceFieldValuesForEdit).length === 0 &&
        Object.keys(occurrenceCauseValuesForEdit).length === 0
      );
    }
    return (
      Object.keys(operatorTripOccurrenceFieldValuesForEdit).length === 0 &&
      Object.keys(linkedTripOccurrenceToBeEdited).length === 0
    );
  };

  const handleFieldChange = (field, value, formType) => {
    const originalValue = originalValues[field];

    if (formType === "linked") {
      setLinkedTripOccurrence((prevLinkedTripOccurrence) => ({
        ...prevLinkedTripOccurrence,
        [field]: value,
      }));

      if (isEditMode) {
        if (value !== originalValue) {
          setLinkedTripOccurrenceToBeEdited(
            (prevLinkedTripOccurrenceToBeEdited) => ({
              ...prevLinkedTripOccurrenceToBeEdited,
              [field]: value,
            })
          );
        } else {
          setLinkedTripOccurrenceToBeEdited((prevLinkedTripOccurrenceToBeEdited) => {
            const updatedEdited = { ...prevLinkedTripOccurrenceToBeEdited };
            delete updatedEdited[field];
            return updatedEdited;
          });
        }
      }
    } else {
      setOccurrencePayload((prevOccurrencePayload) => ({
        ...prevOccurrencePayload,
        [field]: value,
      }));

      if (isEditMode) {
        if (value !== originalValue) {
          setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
            ...prevOccurrenceToBeEdited,
            [field]: value,
          }));
        } else {
          setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => {
            const updatedEdited = { ...prevOccurrenceToBeEdited };
            delete updatedEdited[field];
            return updatedEdited;
          });
        }
      }
    }
  };

  const handleValuesFieldChange = (
    field,
    value,
    setPayload,
    setPayloadForEdit,
    originalValues
  ) => {
    setPayload((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (isEditMode) {
      if (JSON.stringify(value) !== JSON.stringify(originalValues[field])) {
        setPayloadForEdit((prev) => ({
          ...prev,
          [field]: value,
        }));
      } else {
        setPayloadForEdit((prev) => {
          const updatedEdited = { ...prev };
          delete updatedEdited[field];
          return updatedEdited;
        });
      }
    }
  };

  const handleSetOperatorTripOccurrenceFieldValues = (field, value) => {
    handleValuesFieldChange(
      field,
      value,
      setOperatorTripOccurrenceFieldsPayload,
      setOperatorTripOccurrenceFieldValuesForEdit,
      originalOperatorTripOccurrenceFieldValues
    );
  };

  const handleSetOccurrenceCauseValues = (field, value) => {
    handleValuesFieldChange(
      field,
      value,
      setOccurrenceCauseValuesPayload,
      setOccurrenceCauseValuesForEdit,
      originalOccurrenceCauseValues
    );
  };

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        operator_short_name,
        vehicleSearchPayload.carrier_code,
        vehicleSearchPayload.serial_number
      ).then((res) => {
        if (!res.error) {
          if (isEditMode) {
            setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
              ...prevOccurrenceToBeEdited,
              vehicle: res,
            }));
          } else {
            setOccurrencePayload((prevOccurrencePayload) => ({
              ...prevOccurrencePayload,
              vehicle: res,
            }));
          }
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handlePostTripOccurrence = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload = [];
    const formattedOccurrenceCauseValuesPayload = [];

    const occurrenceJson = JSON.parse(JSON.stringify(occurrencePayload));
    const occurrenceCauseValuesJson = JSON.parse(
      JSON.stringify(occurrenceCauseValuesPayload)
    );
    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );

    for (const fieldName in occurrenceCauseValuesJson) {
      const value = {
        field: fieldName,
        value: occurrenceCauseValuesJson[fieldName],
      };

      formattedOccurrenceCauseValuesPayload.push(value);
    }

    for (const fieldName in operatorTripOccurrenceFieldsJson) {
      const value = {
        field: fieldName,
        value: operatorTripOccurrenceFieldsJson[fieldName],
      };

      if (value.value !== "" && value.value !== null) {
        formattedOperatorTripOccurrenceFieldsPayload.push(value);
      }
    }

    const formattedPayload = {
      occurrence: {
        // title: occurrenceJson.title,
        primary: occurrenceJson.primary,
        field_report: false,
        linear_asset_start: occurrenceJson.linear_asset_start,
        linear_asset_end: occurrenceJson.linear_asset_end,
        occurrence_cause_id:
          occurrenceJson?.operator_trip_occurrence_cause_id?.value?.id,
        location_id: occurrenceJson.location.value,
        responsible_party_id: occurrenceJson?.responsible_party_id?.value,
        linear_asset_id: null,
        stationary_asset_id: occurrenceJson?.stationary_asset?.value,
        vehicle_id:
          occurrenceJson?.vehicle === "" ? null : occurrenceJson?.vehicle.id,
        values: formattedOccurrenceCauseValuesPayload,
      },
      trip_occurrence: {
        delay_minutes: occurrenceJson?.delay_minutes,
        values: formattedOperatorTripOccurrenceFieldsPayload,
        trip_status_attribution: false,
        waiver_status: occurrenceJson?.waiver_status,
        comments: occurrenceJson?.comments,
      },
    };

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    setPostingOccurrence(true);
    try {
      await postTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setOccurrenceDrawer(false);
          setTripOccurrenceType("new");
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res, ...trip.trip_occurrences],
              };
            }
          );
          queryClient.setQueryData(
            ["occurrences_operator_service_day", operator_short_name, service_date],
            (oldData) => {
              return [res, ...oldData];
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  const handlePostResidualTripOccurrence = async () => {
    const occurrenceJson = JSON.parse(JSON.stringify(linkedTripOccurrence));
    const occurrenceValuesJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );

    const formattedOperatorTripOccurrenceFieldsPayload = [];

    for (const fieldName in occurrenceValuesJson) {
      const value = { field: fieldName, value: occurrenceValuesJson[fieldName] };

      formattedOperatorTripOccurrenceFieldsPayload.push(value);
    }

    const formattedPayload = {
      occurrence: {},
      trip_occurrence: {
        delay_minutes: occurrenceJson?.delay_minutes,
        comments: occurrenceJson?.comments,
        waiver_status: occurrenceJson?.waiver_status,
        parent_trip_occurrence_id: null,
        trip_status_attribution: false,
        values: formattedOperatorTripOccurrenceFieldsPayload,
      },
    };

    if (
      occurrenceJson?.parent_occurrence &&
      occurrenceJson.parent_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.occurrence_id =
        occurrenceJson?.parent_occurrence?.occurrence_id;
    }

    if (
      occurrenceJson?.parent_trip_occurrence &&
      occurrenceJson.parent_trip_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.parent_trip_occurrence_id =
        occurrenceJson?.parent_trip_occurrence?.value;
    }

    setLinkingOccurrence(true);
    try {
      await postTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setTripOccurrenceType("new");
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          setLinkedTripOccurrence(defaultLinkedOccurrencePayload);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res, ...trip.trip_occurrences],
              };
            }
          );
        }
        setLinkingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setLinkingOccurrence(false);
    }
  };

  useEffect(() => {
    if (
      occurrenceToBeEditedProp &&
      !loadingEditState &&
      Object.keys(occurrenceToBeEdited).length === 0 &&
      Object.keys(linkedTripOccurrenceToBeEdited).length === 0
    ) {
      setIsEditMode(true);
      setOccurrenceDrawer(true);
      const dataToBeEditedJson = JSON.parse(
        JSON.stringify(occurrenceToBeEditedProp)
      );
      setOriginalValues(dataToBeEditedJson);

      const {
        delay_minutes,
        comments,
        waiver_status,
        occurrence,
        trip_status_attribution,
        trip: trip_id,
        trip_occurrence_values,
      } = dataToBeEditedJson;

      const formattedOperatorTripOccurrenceFieldsPayload = {};

      if (trip_occurrence_values) {
        trip_occurrence_values.forEach((value) => {
          formattedOperatorTripOccurrenceFieldsPayload[value.field] = value.value;
        });
      }

      setOperatorTripOccurrenceFieldsPayload(
        formattedOperatorTripOccurrenceFieldsPayload
      );
      setOriginalOperatorTripOccurrenceFieldValues(
        formattedOperatorTripOccurrenceFieldsPayload
      );

      const formattedOccurrence = {
        delay_minutes: delay_minutes,
        waiver_status: waiver_status,
        comments: comments,

        //not sure why these ones are here but leaving for now
        parent_occurrence: occurrence,
        trip_status_attribution: trip_status_attribution,
        reported_only: false, // Assuming default value
        trip_id: trip_id,
      };

      if (dataToBeEditedJson?.primary) {
        setTripOccurrenceType("new");

        const location = occurrence?.location || "N/A";
        const stationary_asset = occurrence?.stationary_asset || "N/A";
        const vehicle = occurrence?.vehicle || "N/A";
        const responsible_party_id = occurrence?.responsible_party || "N/A";
        const occurrence_cause = occurrence?.occurrence_cause || "N/A";

        let currentCause;
        if (Array.isArray(operatorOccurrenceCauses?.data)) {
          currentCause = operatorOccurrenceCauses.data.find(
            (cause) => cause.occurrence_cause.name === occurrence_cause
          );
        }

        let currentLocation;
        if (Array.isArray(trip?.trip_locations)) {
          currentLocation = trip.trip_locations.find(
            (location_lookup) => location_lookup.location.code === location.code
          );
        }

        (formattedOccurrence["operator_trip_occurrence_cause_id"] = {
          value: currentCause?.occurrence_cause,
          label: currentCause?.occurrence_cause?.name,
        }),
          (formattedOccurrence["vehicle"] = vehicle);
        formattedOccurrence["responsible_party_id"] = {
          value: responsible_party_id,
          label: responsible_party_id,
        };
        formattedOccurrence["stationary_asset"] = stationary_asset;
        formattedOccurrence["location"] = {
          value: currentLocation?.location?.id,
          label: currentLocation?.location?.name,
        };

        const formattedOccurrenceCauseValuesPayload = {};

        if (occurrence.occurrence_cause_values) {
          occurrence.occurrence_cause_values.forEach((value) => {
            formattedOccurrenceCauseValuesPayload[
              value.occurrence_cause_field.name
            ] = value.value;
          });
        }

        setOccurrenceCauseFields(currentCause?.occurrence_cause_fields);
        setOccurrenceCauseValuesPayload(formattedOccurrenceCauseValuesPayload);
        setOriginalOccurrenceCauseValues(formattedOccurrenceCauseValuesPayload);
        setOccurrencePayload(formattedOccurrence);
      } else {
        setTripOccurrenceType("linked");

        if (dataToBeEditedJson.parent_trip_occurrence) {
          formattedOccurrence["parent_trip_occurrence"] =
            dataToBeEditedJson.parent_trip_occurrence;
        } else {
          formattedOccurrence["parent_occurrence"] = occurrence;
        }

        setLinkedTripOccurrence(formattedOccurrence);
      }
    }
  }, [occurrenceToBeEditedProp, loadingEditState]);

  const handleEditTripOccurrence = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload = [];
    const formattedOccurrenceCauseValuesPayload = [];

    const editedOccurrenceJson = JSON.parse(JSON.stringify(occurrenceToBeEdited));
    const editedLinkedTripOccurrenceJson = JSON.parse(
      JSON.stringify(linkedTripOccurrenceToBeEdited)
    );

    const occurrenceCauseValuesJson = JSON.parse(
      JSON.stringify(occurrenceCauseValuesForEdit)
    );
    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldValuesForEdit)
    );

    for (const fieldName in occurrenceCauseValuesJson) {
      const value = {
        field: fieldName,
        value: occurrenceCauseValuesJson[fieldName],
      };

      if (value.value !== "" && value.value !== null) {
        formattedOccurrenceCauseValuesPayload.push(value);
      }
    }

    for (const fieldName in operatorTripOccurrenceFieldsJson) {
      const value = {
        field: fieldName,
        value: operatorTripOccurrenceFieldsJson[fieldName],
      };

      if (value.value !== "" && value.value !== null) {
        formattedOperatorTripOccurrenceFieldsPayload.push(value);
      }
    }

    const formattedPayload = {
      occurrence: {
        // ...editedOccurrenceJson,
        occurrence_id: originalValues?.occurrence?.id,
      },
      trip_occurrence: {
        ...editedLinkedTripOccurrenceJson,
        occurrence_id: originalValues?.occurrence?.id,
        trip_occurrence_id: originalValues?.id,
      },
    };

    if (editedOccurrenceJson.location) {
      formattedPayload.occurrence.location_id = editedOccurrenceJson.location.value;
    }

    if (editedOccurrenceJson.responsible_party_id) {
      formattedPayload.occurrence.responsible_party_id =
        editedOccurrenceJson.responsible_party_id.value;
    }

    if (editedOccurrenceJson.operator_trip_occurrence_cause_id) {
      formattedPayload.occurrence.occurrence_cause_id =
        editedOccurrenceJson.operator_trip_occurrence_cause_id.value.id;
    }

    if (formattedOccurrenceCauseValuesPayload.length > 0) {
      formattedPayload.occurrence.values = formattedOccurrenceCauseValuesPayload;
    }

    if (formattedOperatorTripOccurrenceFieldsPayload.length > 0) {
      formattedPayload.trip_occurrence.values =
        formattedOperatorTripOccurrenceFieldsPayload;
    }

    setPostingOccurrence(true);
    try {
      await editTripOccurrence(
        operator_short_name,
        service_date,
        trip?.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setTripOccurrenceType("new");
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              const updatedTripOccurrences = oldData.trip_occurrences.map(
                (trip_occurrence) => {
                  if (trip_occurrence.id === res.id) {
                    return res;
                  }
                  return trip_occurrence;
                }
              );
              return {
                ...oldData,
                trip_occurrences: updatedTripOccurrences,
              };
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  const handleEditResidualTripOccurrence = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload = [];

    const editedLinkedTripOccurrenceJson = JSON.parse(
      JSON.stringify(linkedTripOccurrenceToBeEdited)
    );

    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldValuesForEdit)
    );

    for (const fieldName in operatorTripOccurrenceFieldsJson) {
      const value = {
        field: fieldName,
        value: operatorTripOccurrenceFieldsJson[fieldName],
      };

      if (value.value !== "" && value.value !== null) {
        formattedOperatorTripOccurrenceFieldsPayload.push(value);
      }
    }

    const formattedPayload = {
      occurrence: {},
      trip_occurrence: {
        ...editedLinkedTripOccurrenceJson,
        occurrence_id: originalValues?.occurrence?.id,
        trip_occurrence_id: originalValues?.id,
      },
    };

    setLinkingOccurrence(true);
    try {
      await editTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setTripOccurrenceType("new");
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              const updatedTripOccurrences = oldData.trip_occurrences.map(
                (trip_occurrence) => {
                  if (trip_occurrence.id === res.id) {
                    return res;
                  }
                  return trip_occurrence;
                }
              );
              return {
                ...oldData,
                trip_occurrences: updatedTripOccurrences,
              };
            }
          );
        }
        setLinkingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setLinkingOccurrence(false);
    }
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="add-occurrence-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Delay Details</h2>
        <button
          onClick={() => {
            setOccurrenceDrawer(false);
            setTripOccurrenceType("new");
            resetFormState();
          }}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </button>

        <div className="card card-compact mb-7 p-3 border border-info bg-[#F2ECF7]">
          <div className="flex gap-10">
            <div className="flex gap-5">
              <div>
                <div>
                  <FontAwesomeIcon icon={faTrain} /> Trip
                </div>
                <div className="text-[22px] text-primary font-bold">
                  {trip?.trip_name} <FontAwesomeIcon icon={faArrowUpRight} />
                </div>
              </div>
              <div>
                <div className="text-[#808080]">Date</div>
                <div className="text-[22px] font-bold">{service_date}</div>
              </div>
            </div>

            <div>
              <div className="text-[#808080]">Vehicle Formation</div>
              {firstVehicle?.vehicle && lastVehicle?.vehicle ? (
                <div className="flex gap-1 items-center">
                  <div className="badge badge-md badge-outline">
                    {" "}
                    {firstVehicle?.vehicle?.serial_number}
                  </div>
                  -
                  <div className="badge badge-md badge-outline">
                    {" "}
                    {lastVehicle?.vehicle?.serial_number}
                  </div>
                </div>
              ) : (
                <div className="lg:text-3xl">No Formation</div>
              )}
            </div>
          </div>
        </div>

        {!isEditMode && (
          <div className="join join-horizontal w-full mb-6">
            <button
              className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "new" ? "btn-success" : "btn-outline"}`}
              onClick={() => {
                setTripOccurrenceType("new");
                resetFormState();
              }}
            >
              New
            </button>
            <button
              className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "linked" ? "btn-success" : "btn-outline"}`}
              onClick={() => {
                setTripOccurrenceType("linked");
                resetFormState();
              }}
            >
              Link to Existing
            </button>
          </div>
        )}

        {/* new occurrence form */}
        {tripOccurrenceType === "new" && (
          <div>
            {!isEditMode && (
              <>
                <div className="form-control p-2 border border-[#d3d4d7] rounded">
                  <label className="label gap-2 justify-start cursor-pointer">
                    <Checkbox
                      value={occurrencePayload.primary}
                      handleOnChange={(event) => handleFieldChange("primary", event)}
                    />
                    <span className="label-text">This is a primary occurrence</span>
                  </label>
                </div>
              </>
            )}

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Occurrence Cause <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                isDisabled={operatorOccurrenceCauses.isLoading}
                isLoading={operatorOccurrenceCauses.isLoading}
                placeholder="Pick one"
                formatGroupLabel={FormatGroupLabel}
                value={occurrencePayload.operator_trip_occurrence_cause_id}
                handleOnChange={(operator_trip_occurrence_cause_id) => {
                  setOccurrencePayload((prevState) => ({
                    ...prevState,
                    stationary_asset: "",
                    start: "",
                    end: "",
                    vehicle: "",
                  }));
                  setOccurrenceCauseFields(
                    operator_trip_occurrence_cause_id.value.occurrence_cause_fields
                  );
                  handleFieldChange("operator_trip_occurrence_cause_id", {
                    value: operator_trip_occurrence_cause_id.value.occurrence_cause,
                    label: operator_trip_occurrence_cause_id.label,
                  });
                }}
                options={
                  Array.isArray(operatorOccurrenceCauses?.data) &&
                  Object.values(
                    operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                      const type = item.occurrence_cause.type;
                      if (!acc[type]) {
                        acc[type] = {
                          label: type,
                          options: [],
                        };
                      }
                      acc[type].options.push({
                        value: item,
                        label: item.occurrence_cause.name,
                      });
                      return acc;
                    }, {})
                  )
                }
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Responsible Party <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                isDisabled={responsibleParties.isLoading}
                isLoading={responsibleParties.isLoading}
                placeholder="Pick one"
                value={occurrencePayload.responsible_party_id}
                handleOnChange={(responsible_party_id) =>
                  handleFieldChange("responsible_party_id", responsible_party_id)
                }
                options={
                  responsibleParties?.data &&
                  Array.isArray(responsibleParties?.data) &&
                  [...STATIC_CAUSES, ...responsibleParties.data]?.map((party) => {
                    return { value: party.id, label: party.name };
                  })
                }
              />
            </div>

            {/* static occurrence cause fields based on cause?  */}
            {loadingEditState ? (
              <div className="skeleton min-h-40 col-span-1 mt-4"></div>
            ) : (
              <DynamicOccurrenceCauseFormFields
                occurrencePayload={occurrencePayload}
                handleFieldChange={handleFieldChange}
                setOccurrencePayload={setOccurrencePayload}
                vehicleSearchPayload={vehicleSearchPayload}
                setVehicleSearchPayload={setVehicleSearchPayload}
                searchingVehicle={searchingVehicle}
                handleVehicleLookUp={handleVehicleLookUp}
                stationaryAssets={stationaryAssets}
                linearAssets={linearAssets}
                trip={trip}
                occurrenceCause={
                  occurrencePayload.operator_trip_occurrence_cause_id &&
                  occurrencePayload.operator_trip_occurrence_cause_id
                }
              />
            )}

            {/* occurrence cause fields linked from occurrence causes */}
            <DynamicFormFields
              fields={occurrenceCauseFields}
              payload={occurrenceCauseValuesPayload}
              handleFieldChange={handleSetOccurrenceCauseValues}
            />

            <hr className="my-3" />

            <div className="form-control max-w-20">
              <label className="label">
                <span className="label-text">
                  Delay Minutes <span className="text-error">*</span>
                </span>
              </label>
              <NumberField
                placeholder="00"
                value={occurrencePayload.delay_minutes}
                handleOnChange={(event) =>
                  handleFieldChange("delay_minutes", Number(event.target.value))
                }
              />
            </div>

            {/* dynamic operator trip occurrence fields */}
            <DynamicFormFields
              fields={operatorTripOccurrenceFields?.data?.map((field) => {
                return field?.trip_occurrence_field;
              })}
              payload={operatorTripOccurrenceFieldsPayload}
              handleFieldChange={handleSetOperatorTripOccurrenceFieldValues}
            />

            {/* WAIVER STATUS */}

            <WaiverStatusForm
              payload={occurrencePayload}
              setPayload={setOccurrencePayload}
              handleFieldChange={handleFieldChange}
            />

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Comments
                  <span className="text-error"> *</span>
                </span>
              </label>
              <TextArea
                value={occurrencePayload.comments}
                handleOnChange={(event) =>
                  handleFieldChange("comments", event.target.value)
                }
              />
              <label className="label">
                <span></span>
                <span className="label-text-alt">
                  {occurrencePayload.comments.length}/280
                </span>
              </label>
            </div>
          </div>
        )}
        {/* end of new form */}

        {/* link to existing form */}
        {tripOccurrenceType === "linked" && (
          <div>
            <div>
              <label className="label">
                <span className="label-text">
                  {!linkedTripOccurrence.parent_occurrence ||
                  !linkedTripOccurrence.parent_trip_occurrence ? (
                    <span>
                      Link this to<span className="text-error"> *</span>
                    </span>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faListTree} size="lg" /> Primary
                      Occurrence
                    </div>
                  )}
                </span>
                {linkedTripOccurrence.parent_occurrence &&
                  linkedTripOccurrence.parent_occurrence !== "" && (
                    <button
                      className="btn btn-sm btn-outline btn-error"
                      onClick={() =>
                        handleFieldChange("parent_occurrence", "", "linked")
                      }
                    >
                      UNLINK
                    </button>
                  )}
                {linkedTripOccurrence.parent_trip_occurrence &&
                  linkedTripOccurrence.parent_trip_occurrence !== "" && (
                    <button
                      className="btn btn-sm btn-outline btn-error"
                      onClick={() =>
                        handleFieldChange("parent_trip_occurrence", "", "linked")
                      }
                    >
                      UNLINK
                    </button>
                  )}
              </label>
              {!linkedTripOccurrence.parent_occurrence &&
              !linkedTripOccurrence.parent_trip_occurrence ? (
                <SingleSelect
                  isLoading={occurrencesOperatorServiceDay.isLoading}
                  isDisabled={occurrencesOperatorServiceDay.isLoading}
                  formatGroupLabel={FormatGroupLabel}
                  placeholder="Select Occurrence"
                  value={linkedTripOccurrence.parent_occurrence}
                  handleOnChange={(parent_occurrence) =>
                    handleFieldChange(
                      "parent_trip_occurrence",
                      parent_occurrence,
                      "linked"
                    )
                  }
                  options={
                    Array.isArray(occurrencesOperatorServiceDay.data) &&
                    occurrencesOperatorServiceDay?.data?.map((occurrence) => {
                      let options = [];
                      if (occurrence.linked_trip_occurrences) {
                        options = occurrence?.linked_trip_occurrences?.map(
                          (linked_trip_occurrence) => {
                            return {
                              value: linked_trip_occurrence.trip_occurrence_id,
                              label: linked_trip_occurrence.description,
                            };
                          }
                        );
                      }

                      return {
                        onClick: (occurrence) => {
                          handleFieldChange(
                            "parent_occurrence",
                            occurrence,
                            "linked"
                          );
                        },
                        value: occurrence,
                        label: occurrence.name,
                        options: options,
                      };
                    })
                  }
                />
              ) : (
                <LinkedTripOccurrenceCard
                  linkedTripOccurrence={linkedTripOccurrence}
                  isEditMode={isEditMode}
                />
              )}
            </div>

            <div className="form-control max-w-20">
              <label className="label">
                <span className="label-text">
                  Delay Minutes <span className="text-error">*</span>
                </span>
              </label>
              <NumberField
                placeholder="00"
                value={linkedTripOccurrence.delay_minutes}
                handleOnChange={(event) =>
                  handleFieldChange(
                    "delay_minutes",
                    Number(event.target.value),
                    "linked"
                  )
                }
              />
            </div>

            {/* dynamic operator trip occurrence fields */}
            <DynamicFormFields
              fields={operatorTripOccurrenceFields?.data?.map((field) => {
                return field?.trip_occurrence_field;
              })}
              payload={operatorTripOccurrenceFieldsPayload}
              setPayload={setOperatorTripOccurrenceFieldsPayload}
              handleFieldChange={handleSetOperatorTripOccurrenceFieldValues}
            />

            {/* WAIVER STATUS */}
            <WaiverStatusForm
              payload={linkedTripOccurrence}
              setPayload={setLinkedTripOccurrence}
              handleFieldChange={handleFieldChange}
              formType={"linked"}
            />

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Mechanical Department Comments
                  <span className="text-error">*</span>
                </span>
              </label>
              <TextArea
                value={linkedTripOccurrence.comments}
                handleOnChange={(event) =>
                  handleFieldChange("comments", event.target.value, "linked")
                }
              />
              <label className="label">
                <span></span>
                <span className="label-text-alt">
                  {occurrencePayload.comments.length}/280
                </span>
              </label>
            </div>
          </div>
        )}
        {/* end of link to existing form */}

        <hr className="my-3" />

        <div className="flex gap-2 mt-5">
          <button
            onClick={() => {
              setOccurrenceDrawer(false);
              setTripOccurrenceType("new");
              resetFormState();
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={
              (tripOccurrenceType === "new" &&
                (occurrencePayload.comments.length > 280 ||
                  occurrencePayload.comments === "" ||
                  occurrencePayload.operator_trip_occurrence_cause_id === "" ||
                  occurrencePayload.operator_trip_occurrence_cause_id === null ||
                  occurrencePayload.delay_minutes === "" ||
                  occurrencePayload.delay_minutes === "0" ||
                  occurrencePayload.waiver_status === "" ||
                  occurrencePayload.responsible_party_id === "" ||
                  occurrencePayload.responsible_party_id === null ||
                  postingOccurrence)) ||
              (tripOccurrenceType === "linked" &&
                ((linkedTripOccurrence.parent_occurrence === "" &&
                  linkedTripOccurrence.parent_occurrence === null &&
                  linkedTripOccurrence.parent_trip_occurrence === "" &&
                  linkedTripOccurrence.parent_trip_occurrence === null) ||
                  linkedTripOccurrence.delay_minutes === "" ||
                  linkedTripOccurrence.delay_minutes === "0" ||
                  linkedTripOccurrence.waiver_status === "" ||
                  linkedTripOccurrence.comments.length > 280 ||
                  linkedTripOccurrence.comments === "" ||
                  linkingOccurrence)) ||
              isSaveDisabled()
            }
            onClick={(event) => {
              event.preventDefault();
              if (tripOccurrenceType === "new") {
                if (isEditMode) {
                  handleEditTripOccurrence();
                } else {
                  handlePostTripOccurrence();
                }
              } else if (tripOccurrenceType === "linked") {
                if (isEditMode) {
                  handleEditResidualTripOccurrence();
                } else {
                  handlePostResidualTripOccurrence();
                }
              }
            }}
          >
            Save
            {(postingOccurrence || linkingOccurrence) && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </div>
  );
}
