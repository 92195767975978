import LinearAssetField from "./LinearAssetField";
import LocationField from "./LocationField";
import TrainField from "./TrainField";
import StationaryAssetsField from "./StationaryAssetsField";

export default function DynamicOccurrenceCauseFormFields({
  occurrencePayload,
  setOccurrencePayload,
  vehicleSearchPayload,
  setVehicleSearchPayload,
  searchingVehicle,
  handleVehicleLookUp,
  stationaryAssets,
  linearAssets,
  trip,
  occurrenceCause,
  handleFieldChange,
  hideLinearAssetField,
  linearAssetSelectionRequired,
}) {
  const {
    location = "NOT SHOWN",
    linear_asset = "NOT SHOWN",
    stationary_asset = "NOT SHOWN",
    vehicle = "NOT SHOWN",
  } = occurrenceCause?.value || {};

  const isFieldVisible = (fieldValue) =>
    fieldValue === "REQUIRED" || fieldValue === "OPTIONAL";

  console.log("stationaryAssets", stationaryAssets);

  return (
    <>
      {occurrenceCause && (
        <div>
          <hr className="mt-4 mb-3" />
          {isFieldVisible(linear_asset) && !hideLinearAssetField && (
            <LinearAssetField
              handleFieldChange={handleFieldChange}
              linear_asset={linear_asset}
              occurrencePayload={occurrencePayload}
              setOccurrencePayload={setOccurrencePayload}
              linearAssets={linearAssets}
            />
          )}
          {isFieldVisible(location) && (
            <LocationField
              handleFieldChange={handleFieldChange}
              location={location}
              occurrencePayload={occurrencePayload}
              setOccurrencePayload={setOccurrencePayload}
              trip={trip}
            />
          )}
          {isFieldVisible(vehicle) && (
            <TrainField
              handleFieldChange={handleFieldChange}
              vehicle={vehicle}
              occurrencePayload={occurrencePayload}
              setOccurrencePayload={setOccurrencePayload}
              handleVehicleLookUp={handleVehicleLookUp}
              searchingVehicle={searchingVehicle}
              vehicleSearchPayload={vehicleSearchPayload}
              setVehicleSearchPayload={setVehicleSearchPayload}
            />
          )}
          {isFieldVisible(stationary_asset) && (
            <StationaryAssetsField
              handleFieldChange={handleFieldChange}
              stationary_asset={stationary_asset}
              occurrencePayload={occurrencePayload}
              setOccurrencePayload={setOccurrencePayload}
              stationaryAssets={stationaryAssets}
              linearAssetSelectionRequired={linearAssetSelectionRequired}
            />
          )}
        </div>
      )}
    </>
  );
}
