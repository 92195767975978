import { useState, useEffect } from "react";
import CopyExistingTypeahead from "../CopyExistingTypeahead";
import SingleSelect from "../SingleSelect";
import DatePicker from "react-datepicker";
import TextField from "../TextField";
import { useQueries } from "@tanstack/react-query";
import { useFetchFunctions } from "../../services/trips";
import { formatInTimeZone, format } from "date-fns-tz";
import NumberField from "../NumberField";
import Checkbox from "../Checkbox";
import DateTimePicker from "../DateTimePicker";
import LoadingSpinner from "../../icons/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import RouteCard from "../trip-detail/trip-locations/RouteCard";

const defaultCreateTripPayload = {
  service_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  Name: "",
  origin: "",
  destination: "",
  route: "",
  Values: [],
  Locations: [],
};

export default function CreateTrip({
  addTripDrawerIsOpen,
  setAddTripDrawerIsOpen,
  isAuthenticated,
}) {
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [createTripPayload, setCreateTripPayload] = useState(
    defaultCreateTripPayload
  );
  const [creatingTrip, setCreatingTrip] = useState(false);
  const navigate = useNavigate();

  const {
    fetchOperators,
    fetchLocationsByTransportationMode,
    fetchPaths,
    postTrip,
    fetchOperatorTripFields,
  } = useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        enabled: isAuthenticated && addTripDrawerIsOpen,
        staleTime: 1000 * 10,
      },
      {
        queryKey: [
          "locations_by_transportation_mode",
          selectedOperator?.value?.transportation_mode?.name,
        ],
        queryFn: () =>
          fetchLocationsByTransportationMode(
            selectedOperator?.value?.transportation_mode?.name
          ),
        enabled: !!selectedOperator,
        staleTime: 1000 * 10,
      },
      {
        queryKey: [
          "routes",
          selectedOperator?.value?.short_name,
          createTripPayload.origin,
          createTripPayload.destination,
        ],
        queryFn: () =>
          fetchPaths(
            selectedOperator?.value?.short_name,
            createTripPayload?.origin?.value?.code,
            createTripPayload?.destination?.value?.code
          ),
        enabled:
          !!selectedOperator &&
          !!createTripPayload.origin &&
          !!createTripPayload.destination,
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_fields", selectedOperator?.value?.short_name],
        queryFn: () => fetchOperatorTripFields(selectedOperator?.value?.short_name),
        enabled: !!selectedOperator,
        staleTime: 1000 * 10,
      },
    ],
  });

  const operators = results[0];
  const locations = results[1];
  const routes = results[2];
  const operatorTripFields = results[3];

  useEffect(() => {
    if (!selectedOperator) {
      setCreateTripPayload(defaultCreateTripPayload);
    }
    if (
      Array.isArray(operatorTripFields?.data) &&
      operatorTripFields?.data &&
      !operatorTripFields.isLoading
    ) {
      const dynamicPayloadFields = {};
      operatorTripFields?.data?.forEach((tripField) => {
        if (tripField?.for_trip_create) {
          dynamicPayloadFields[tripField?.trip_field?.name] = "";
        }
      });
      setCreateTripPayload({ ...createTripPayload, Values: dynamicPayloadFields });
    }
  }, [operatorTripFields.data, operatorTripFields.isLoading, selectedOperator]);

  const handleClickRoute = (route) => {
    setCreateTripPayload({
      ...createTripPayload,
      route: route,
    });
  };

  const handlePostTrip = async () => {
    const formattedPayload = JSON.parse(JSON.stringify(createTripPayload));

    if (!formattedPayload.Locations) {
      formattedPayload.Locations = [];
    }

    formattedPayload.route?.detail?.forEach((location) => {
      const formattedLocation = {
        Location: location?.code || "",
        Scheduled_Arrival: null,
        Scheduled_Departure: null,
        Is_Stopping: false,
        Sequence: location?.sequence || 0,
        Scheduled_Location: false,
        Values: [],
      };

      formattedPayload.Locations.push(formattedLocation);
    });

    const formattedValues = Object.entries(formattedPayload.Values || {})
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => ({
        Field: key,
        Value: typeof value === "object" ? value?.value : value,
      }));

    formattedPayload.Values = formattedValues;

    const operator_short_name = selectedOperator?.value?.short_name;
    let service_date;
    if (typeof formattedPayload?.service_date === "string") {
      service_date = format(
        formattedPayload?.service_date.replace(/-/g, "/"),
        "yyyy-MM-dd"
      );
    } else {
      service_date = formatInTimeZone(
        formattedPayload?.service_date,
        "America/New_York",
        "yyyy-MM-dd"
      );
    }

    setCreatingTrip(true);
    try {
      await postTrip(operator_short_name, service_date, formattedPayload).then(
        (res) => {
          setCreatingTrip(false);
          if (!res.error) {
            setAddTripDrawerIsOpen(false);
            setCreateTripPayload(defaultCreateTripPayload);
            toast.success(`Trip ${res?.trip?.trip_name} created.`);
            navigate(
              `/trips/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${res?.trip?.trip_name}`
            );
          }
        }
      );
    } catch (error) {
      console.log(error);
      setCreatingTrip(false);
    }
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="add-trip-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Create Trip</h2>
        <label
          htmlFor="add-trip-drawer"
          onClick={() => setAddTripDrawerIsOpen(false)}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>

        <CopyExistingTypeahead
          disabled={true}
          buttonText={"Copy From Existing Trip"}
          title={"Original Trip"}
          exampleText={"Ex: 1701 - 3/27/24"}
          placeHolder={"Select Trip"}
        />

        <form action="" className="flex flex-col gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                Opperator <span className="text-error">*</span>
              </span>
            </label>
            <SingleSelect
              placeholder={"Select operator..."}
              value={selectedOperator}
              isLoading={operators.isLoading}
              handleOnChange={(o) => setSelectedOperator(o)}
              options={
                operators &&
                Array.isArray(operators?.data) &&
                operators?.data?.map((operator) => {
                  return { value: operator, label: operator.name };
                })
              }
            />
          </div>

          {Array.isArray(operatorTripFields?.data) &&
            operatorTripFields?.data
              ?.filter((tripField) => tripField?.for_trip_create)
              .map((tripField, index) => {
                if (tripField?.trip_field?.type === "INTEGER") {
                  return (
                    <div className="form-control" key={index}>
                      <label className="label">
                        <span className="label-text">
                          {tripField?.trip_field?.label}{" "}
                          <span className="text-error">*</span>
                        </span>
                      </label>
                      <NumberField
                        placeholder="00"
                        value={createTripPayload.Values[tripField?.trip_field?.name]}
                        handleOnChange={(event) =>
                          setCreateTripPayload({
                            ...createTripPayload,
                            Values: {
                              ...createTripPayload.Values,
                              [tripField?.trip_field?.name]: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }
                if (
                  tripField?.trip_field?.type === "TEXT" &&
                  tripField?.lookup_list_items?.length === 0
                ) {
                  return (
                    <div className="form-control" key={index}>
                      <label className="label">
                        <span className="label-text">
                          {tripField?.trip_field?.label}{" "}
                          <span className="text-error">*</span>
                        </span>
                      </label>
                      <TextField
                        className={"flex items-center gap-2"}
                        value={createTripPayload.Values[tripField?.trip_field?.name]}
                        placeholder={tripField?.trip_field?.label}
                        handleOnChange={(event) =>
                          setCreateTripPayload({
                            ...createTripPayload,
                            Values: {
                              ...createTripPayload.Values,
                              [tripField?.trip_field?.name]: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }
                if (
                  tripField?.trip_field?.type === "TEXT" &&
                  tripField?.lookup_list_items?.length > 0
                ) {
                  return (
                    <div className="form-control" key={index}>
                      <label className="label">
                        <span className="label-text">
                          {tripField?.trip_field?.label}{" "}
                          <span className="text-error">*</span>
                        </span>
                      </label>
                      <SingleSelect
                        value={createTripPayload.Values[tripField?.trip_field?.name]}
                        placeholder={tripField?.trip_field?.label}
                        options={tripField?.lookup_list_items?.map((item) => {
                          return { value: item, label: item };
                        })}
                        handleOnChange={(event) =>
                          setCreateTripPayload({
                            ...createTripPayload,
                            Values: {
                              ...createTripPayload.Values,
                              [tripField?.trip_field?.name]: event,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }
                if (tripField.trip_field.type === "BOOLEAN") {
                  return (
                    <div className="form-control" key={index}>
                      <label className="label">
                        <span className="label-text">
                          {tripField?.trip_field?.label}{" "}
                          <span className="text-error">*</span>
                        </span>
                      </label>
                      <Checkbox
                        string={true}
                        value={createTripPayload.Values[tripField?.trip_field?.name]}
                        handleOnChange={(event) =>
                          setCreateTripPayload({
                            ...createTripPayload,
                            Values: {
                              ...createTripPayload.Values,
                              [tripField?.trip_field?.name]: event,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }
                if (tripField.trip_field.type === "DATETIME") {
                  return (
                    <div className="form-control" key={index}>
                      <label className="label">
                        <span className="label-text">
                          {tripField?.trip_field?.label}{" "}
                          <span className="text-error">*</span>
                        </span>
                      </label>
                      <DateTimePicker
                        handleOnChange={(event) =>
                          setCreateTripPayload({
                            ...createTripPayload,
                            Values: {
                              ...createTripPayload.Values,
                              [tripField?.trip_field?.name]: event,
                            },
                          })
                        }
                        value={createTripPayload.Values[tripField?.trip_field?.name]}
                      />
                    </div>
                  );
                }
              })}

          <div className="flex gap-3">
            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Service Date <span className="text-error">*</span>
                </span>
              </label>
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                selected={createTripPayload.service_date}
                onChange={(date) => {
                  setCreateTripPayload({
                    ...createTripPayload,
                    service_date: date,
                  });
                }}
                className="input input-bordered input-sm lg:input-md w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Trip Name <span className="text-error">*</span>
                </span>
              </label>
              <TextField
                placeholder={"Trip Name"}
                value={createTripPayload.Name}
                handleOnChange={(event) => {
                  setCreateTripPayload({
                    ...createTripPayload,
                    Name: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex gap-3">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  Origin <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder={"Select origin..."}
                isDisabled={!selectedOperator}
                value={createTripPayload.origin}
                isLoading={locations.isLoading}
                handleOnChange={(event) => {
                  setCreateTripPayload({
                    ...createTripPayload,
                    origin: event,
                  });
                }}
                options={
                  locations &&
                  Array.isArray(locations?.data) &&
                  locations?.data?.map((location) => {
                    return {
                      value: location,
                      label: location.name,
                    };
                  })
                }
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  Destination <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                isDisabled={!createTripPayload.origin}
                placeholder={"Select destination..."}
                value={createTripPayload.destination}
                isLoading={locations.isLoading}
                handleOnChange={(event) => {
                  setCreateTripPayload({
                    ...createTripPayload,
                    destination: event,
                  });
                }}
                options={
                  locations &&
                  Array.isArray(locations?.data) &&
                  locations?.data?.map((location) => {
                    return {
                      value: location,
                      label: location.name,
                    };
                  })
                }
              />
            </div>
          </div>
        </form>

        <div className="my-8">
          <div className="flex gap-5 -mx-1 overflow-x-auto">
            {routes?.isLoading && <LoadingSpinner />}
            {Array.isArray(routes?.data) &&
              routes?.data?.map((route, index) => {
                return (
                  <RouteCard
                    route={route}
                    key={index}
                    index={index}
                    handleClickRoute={handleClickRoute}
                    selectedRoute={createTripPayload?.route?.path_id}
                  />
                );
              })}
            {routes?.data?.length === 0 && (
              <div className="italic mx-1">
                No routes available between the two selected locations
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setAddTripDrawerIsOpen(false);
              setCreateTripPayload(defaultCreateTripPayload);
              setSelectedOperator(null);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handlePostTrip()}
            disabled={
              creatingTrip ||
              !selectedOperator ||
              !createTripPayload ||
              Object.values(createTripPayload.Values).some((value) => value === "")
            }
          >
            Create Trip
            {creatingTrip && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </div>
  );
}
