import { formatInTimeZone } from "date-fns-tz";
import { OccurrenceSearchPayload } from "../../types/occurrences";

export const TRIP_STATUS_OPTIONS = [
  { value: "NONE", label: "None" },
  { value: "ON_TIME", label: "On Time" },
  { value: "LATE", label: "Late" },
  { value: "CANCELED", label: "Canceled" },
  { value: "TERMINATED", label: "Terminated" },
  { value: "ORIGINATED_ENROUTE", label: "Originated Enroute" },
];

export const defaultOccurrenceSearchPayload: OccurrenceSearchPayload = {
  responsible_party_id: "",
  from_service_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  to_service_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  occurrence_cause_id: 0,
  waiver_status: "",
  primary: true,
  trip_name: "",
  linear_asset_id: 0,
  stationary_asset_id: 0,
  carrier_code: "",
  serial_number: "",
  location_id: 0,
  occurrence_cause_values: {},
  trip_occurrence_values: {},
  trip_values: {},
};
