import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCheck,
  // faArrowLeftToLine,
  // faArrowRightFromLine,
  faTimes,
  faSlidersH,
} from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "../SingleSelect";
import { useNavigate } from "react-router-dom";
import { ManageColumns } from "../../utils/tableFunctions";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const OPTIONS = ["Test option 1", "Test option 2", "Test option 3"];

const DELAYS_COLUMNS = [
  { name: "service_date", display_name: "Date", visible: true },
  { name: "trip_name", display_name: "Trip Name", visible: true },
  { name: "primary", display_name: "Primary", visible: true },
  { name: "delay_minutes", display_name: "Min", visible: true },
  { name: "occurrence_type", display_name: "Type", visible: true },
  { name: "occurrence_cause", display_name: "Cause", visible: true },
  { name: "responsible_party_name", display_name: "responsible", visible: true },
  { name: "comments", display_name: "Comments", visible: true },
  { name: "waiver_status", display_name: "Status", visible: true },
];

const UNUSUAL_OCCURRENCES_COLUMNS = [
  { name: "start_date", display_name: "Start Date", visible: true },
  { name: "start_time", display_name: "Start Time", visible: true },
  { name: "title", display_name: "Title", visible: true },
  { name: "operator", display_name: "Operator", visible: true },
  { name: "primary", display_name: "Primary", visible: true },
  { name: "occurrence_type", display_name: "Type", visible: true },
  { name: "occurrence_cause", display_name: "Cause", visible: true },
  { name: "responsible_party_name", display_name: "Responsible", visible: true },
];

const excludedColumns = [
  "mins_late",
  "vehicle_formation",
  "occurrences",
  "signed_off",
  "warnings",
];

function DelaysTableRow({ delay, columns, operator, visibleTableColumns }) {
  const {
    service_date,
    trip_name,
    primary,
    delay_minutes,
    occurrence_type,
    occurrence_cause,
    responsible_party_name,
    comments,
    waiver_status,
    trip_values,
  } = delay;

  // const TripStatus = ({ trip_status }) => {
  //   const statusColor = statusColors[trip_status] || "";

  //   return (
  //     <div className={`badge badge-sm badge-${statusColor} whitespace-nowrap`}>
  //       {trip_status}
  //     </div>
  //   );
  // };

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const dynamicColumns = [...visibleTableColumns].filter(
    (column) =>
      !DELAYS_COLUMNS.some((staticColumn) => column.name === staticColumn.name)
  );

  return (
    <tr className="hover:bg-[#E5F6F9] hover:cursor-pointer">
      {columnIsVisible("service_date") && (
        <td>
          {service_date && format(service_date.replace(/-/g, "/"), "MM/dd/yyyy")}
        </td>
      )}
      {columnIsVisible("trip_name") && <td>{trip_name}</td>}
      {columnIsVisible("primary") && <td>{primary}</td>}
      {columnIsVisible("delay_minutes") && <td>{delay_minutes}</td>}
      {columnIsVisible("occurrence_type") && <td>{occurrence_type}</td>}
      {columnIsVisible("occurrence_cause") && <td>{occurrence_cause}</td>}
      {columnIsVisible("responsible_party_name") && (
        <td>{responsible_party_name}</td>
      )}
      {columnIsVisible("comments") && (
        <td className="truncate max-w-40">{comments}</td>
      )}
      {columnIsVisible("waiver_status") && <td>{waiver_status}</td>}

      {/* dynamic  */}

      {dynamicColumns
        .filter((column) =>
          visibleTableColumns.some(
            (visibleColumn) =>
              visibleColumn?.name === column.name && visibleColumn?.visible === true
          )
        )
        ?.map((trip_value, key) => {
          const matchingTripValue = trip_values?.find(
            (value) => value?.name === trip_value?.name
          );

          if (
            typeof matchingTripValue?.value === "boolean" ||
            matchingTripValue?.value === "true" ||
            matchingTripValue?.value === "false"
          ) {
            return (
              <td key={key} className="text-center">
                {typeof matchingTripValue?.value === "boolean" ||
                  (matchingTripValue?.value === "true" ? (
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-error" />
                  ))}
              </td>
            );
          } else if (matchingTripValue?.label === "Direction") {
            return (
              <td key={key} className="flex gap-2 items-center">
                {matchingTripValue?.value}
              </td>
            );
          } else {
            return <td key={key}>{matchingTripValue?.value}</td>;
          }
        })}
    </tr>
  );
}

function UnusualOccurrencesTableRow({
  delay,
  columns,
  visibleTableColumns,
  rowCounter,
  selectedRow,
  setSelectedRow,
}) {
  const {
    start_date,
    start_time,
    title,
    operator,
    primary,
    occurrence_type,
    occurrence_cause,
    responsible_party_name,
    linked_trip_occurrences,
  } = delay;

  // const TripStatus = ({ trip_status }) => {
  //   const statusColor = statusColors[trip_status] || "";

  //   return (
  //     <div className={`badge badge-sm badge-${statusColor} whitespace-nowrap`}>
  //       {trip_status}
  //     </div>
  //   );
  // };

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const isSelected = selectedRow === rowCounter;

  const handleSelectRow = () => {
    if (isSelected) {
      setSelectedRow("");
    } else {
      setSelectedRow(rowCounter);
    }
  };

  return (
    <>
      <tr
        className={`hover:bg-[#E5F6F9] hover:cursor-pointer ${isSelected ? "bg-[#E5F6F9]" : ""}`}
        onClick={() => handleSelectRow()}
      >
        {columnIsVisible("start_date") && (
          <td>
            {start_date && format(start_date.replace(/-/g, "/"), "MM/dd/yyyy")}
          </td>
        )}
        {columnIsVisible("start_time") && <td>{start_time}</td>}
        {columnIsVisible("title") && <td>{title}</td>}
        {columnIsVisible("primary") && <td>{primary}</td>}
        {columnIsVisible("operator") && <td>{operator}</td>}
        {columnIsVisible("occurrence_type") && <td>{occurrence_type}</td>}
        {columnIsVisible("occurrence_cause") && <td>{occurrence_cause}</td>}
        {columnIsVisible("responsible_party_name") && (
          <td>{responsible_party_name}</td>
        )}
      </tr>

      {isSelected &&
        linked_trip_occurrences &&
        Array.isArray(linked_trip_occurrences) &&
        linked_trip_occurrences.map((linked_trip_occurrence) => {
          const { trip_occurrence_id, description } = linked_trip_occurrence;

          return (
            <tr className="hover:bg-[#E5F6F9] hover:cursor-pointer">
              <td>{trip_occurrence_id}</td>
              <td>{description}</td>
            </tr>
          );
        })}
    </>
  );
}

function TableRowMobile({ trip, operator }) {
  const navigate = useNavigate();
  const {
    service_date,
    trip_name,
    origination_time,
    mins_late,
    formation,
    occurrences,
    warnings,
    trip_values,
  } = trip;
  const urlFormattedDate =
    service_date && format(service_date?.replace(/-/g, "/"), "yyyy-MM-dd");

  const handleRowClick = () => {
    const navigateTo = `/trips/operator/${operator.value}/operator_service_day/${urlFormattedDate}/trip/${trip_name}`;

    navigate(navigateTo);
  };

  // const TripStatus = ({ trip_status }) => {
  //   const statusColor = statusColors[trip_status] || "";

  //   return (
  //     <div className={`badge badge-sm badge-${statusColor} whitespace-nowrap`}>
  //       {trip_status}
  //     </div>
  //   );
  // };

  return (
    <div
      className="px-4 py-2 border-b hover:bg-[#E5F6F9] hover:cursor-pointer"
      onClick={handleRowClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center font-bold">
          {trip_name}
          {warnings && (
            <span className="badge badge-error badge-sm rounded-full">
              {warnings}
            </span>
          )}
        </div>
        {/* <TripStatus trip_status={trip_status} /> */}
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>
          {service_date && format(service_date.replace(/-/g, "/"), "MM/dd/yyyy")}{" "}
          {origination_time && formatInTimeZone(origination_time, "UTC", "h:mm a")}
        </div>
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>Route Here</div>
        {trip_values?.map((trip_value, key) => {
          if (trip_value.label === "Direction") {
            return <div>{trip_value.value}</div>;
          }
        })}
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>+ {mins_late} mins</div>
        <div>{formation}</div>
        <div>{occurrences} delays</div>
      </div>
    </div>
  );
}

const defineDynamicColumns = (trip_values: []) => {
  const dynamicColumns: { name: string; display_name: string; visible: boolean }[] =
    [];

  for (const trip_value of trip_values) {
    if (!dynamicColumns.some((col) => col.name === trip_value.name)) {
      dynamicColumns.push({
        name: trip_value.name,
        display_name: trip_value.label,
        visible: true,
      });
    }
  }

  return dynamicColumns;
};

export default function ManageOccurrencesTable({
  data,
  selectedOperator,
  sort,
  sort_by,
  handleChangeSort,
  tableRef,
  searchResultsTab,
  setSearchResultsTab,
  setDelaysDrawerIsOpen,
  setUnusualOccurrencesDrawerIsOpen,
  operator_trip_fields,
  handleSetSearchResultsTab,
}) {
  const [visibleTableColumns, setVisibleTableColumns] = useState(DELAYS_COLUMNS);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  useEffect(() => {
    if (searchResultsTab === "delay") {
      if (data) {
        const newColumns = data[0].trip_values
          ? defineDynamicColumns(data[0].trip_values)
          : [];
        setVisibleTableColumns([...DELAYS_COLUMNS, ...newColumns]);
      } else {
        setVisibleTableColumns(DELAYS_COLUMNS);
      }
    } else {
      setVisibleTableColumns(UNUSUAL_OCCURRENCES_COLUMNS);
    }
  }, [data, searchResultsTab]);

  return (
    <>
      <div className="card-header py-2 px-4 border-b" ref={tableRef}>
        <div className="flex justify-between items-center">
          <h3 className="m-0">Search Results</h3>
          <div className="flex gap-3">
            <button
              onClick={() => setDisplayFilters(!displayFilters)}
              className="btn btn-sm btn-primary btn-outline"
            >
              Filters <FontAwesomeIcon icon={faSlidersH} />
            </button>
            <ManageColumns
              columns={visibleTableColumns}
              setVisibleTableColumns={setVisibleTableColumns}
            />
          </div>
        </div>
        <div className="flex">
          <button
            className={`btn btn-primary  ${searchResultsTab === "delay" ? "btn-fill" : "btn-outline"}`}
            onClick={() => handleSetSearchResultsTab("delay")}
          >
            Delays
          </button>
          <button
            className={`btn btn-primary  ${searchResultsTab === "unusual_occurrence" ? "btn-fill" : "btn-outline"}`}
            onClick={() => handleSetSearchResultsTab("unusual_occurrence")}
          >
            Unusual Occurrences
          </button>
        </div>
      </div>

      {/* <div className="mobile-results md:hidden">
        {data?.map((trip, id) => {
          return <TableRowMobile trip={trip} key={id} operator={selectedOperator} />;
        })}
      </div> */}

      <div className="desktop-results hidden md:block overflow-x-auto">
        <table className="table m-0 lg:text-base">
          {/* head */}
          <thead>
            <tr>
              {visibleTableColumns.map((column, id) => {
                const isSortable = !excludedColumns.includes(column.name);
                if (column.visible) {
                  const isDynamicSort = !DELAYS_COLUMNS.some(
                    (staticColumn) => staticColumn.name === column.name
                  );
                  return (
                    <th
                      className={
                        isSortable ? "hover:bg-[#E5F6F9] hover:cursor-pointer" : ""
                      }
                      key={id}
                      onClick={
                        isSortable
                          ? () =>
                              handleChangeSort(
                                `${sort === "DESC" ? "ASC" : "DESC"}`,
                                column.name,
                                isDynamicSort
                              )
                          : undefined
                      }
                    >
                      <div className="flex gap-2 items-center">
                        {column.display_name}
                        {data && column.name === sort_by && (
                          <div>
                            <FontAwesomeIcon
                              icon={sort === "DESC" ? faArrowUp : faArrowDown}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((delay, index) => {
              if (searchResultsTab === "unusual_occurrence") {
                return (
                  <UnusualOccurrencesTableRow
                    delay={delay}
                    key={index}
                    rowCounter={index}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    columns={visibleTableColumns}
                    visibleTableColumns={visibleTableColumns}
                  />
                );
              } else {
                return (
                  <DelaysTableRow
                    delay={delay}
                    key={index}
                    columns={visibleTableColumns}
                    visibleTableColumns={visibleTableColumns}
                  />
                );
              }
            })}
            {/* {searchResultsTab === "delay" && (
              <tr>
                <td onClick={() => setDelaysDrawerIsOpen(true)}>
                  delay search result
                </td>
              </tr>
            )} */}
            {/* {searchResultsTab === "unusual_occurrence" && (
              <tr>
                <td onClick={() => setUnusualOccurrencesDrawerIsOpen(true)}>
                  unusual occurrence result
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </>
  );
}
