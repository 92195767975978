import SingleSelect from "../SingleSelect";
import MultiSelect from "../MultiSelect";
import DateRangePicker from "../DateRangePicker";
import TextField from "../TextField";
import LoadingSpinner from "../../icons/LoadingSpinner";
import Checkbox from "../Checkbox";
import { useState, useContext, useEffect } from "react";
import { useFetchFunctions } from "../../services/trips";
import "react-datepicker/dist/react-datepicker.css";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns-tz";
import { OccurrenceSearchPayload } from "../../types/occurrences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "../Pagination";
import ManageOccurrencesTable from "./ManageOccurrencesTable";
import DelaysDrawer from "./DelaysDrawer";
import UnusualOccurrencesDrawer from "./UnusualOccurrencesDrawer";
import { defaultOccurrenceSearchPayload, TRIP_STATUS_OPTIONS } from "./constants";
import { NavigationContext } from "../../utils/Contexts";

export default function ManageOccurrences() {
  const [searchPayload, setSearchPayload] = useState<OccurrenceSearchPayload>(
    defaultOccurrenceSearchPayload
  );
  const [selectedOperator, setSelectedOperator] = useState("");
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchResultsTab, setSearchResultsTab] = useState("delay");
  const [delaysDrawerIsOpen, setDelaysDrawerIsOpen] = useState(false);
  const [unusualOccurrencesDrawerIsOpen, setUnusualOccurrencesDrawerIsOpen] =
    useState(false);

  const { currentNavigationContext, setCurrentNavigationContext } =
    useContext(NavigationContext);

  useEffect(() => {
    setCurrentNavigationContext("manage-occurrences");
  }, []);

  const {
    fetchOperators,
    fetchScheduleTypes,
    fetchOperatorTripFields,
    searchOccurrences,
    fetchResponsibleParties,
    fetchOperatorOccurrenceCauses,
  } = useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["schedule_types"],
        queryFn: () => fetchScheduleTypes(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_fields", selectedOperator?.value],
        queryFn: () => fetchOperatorTripFields(selectedOperator?.value),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_occurrence_causes", selectedOperator?.value],
        queryFn: () => fetchOperatorOccurrenceCauses(selectedOperator?.value),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
    ],
  });

  const operators = results[0];
  const scheduleTypes = results[1];
  const operatorTripFields = results[2];
  const responsibleParties = results[3];

  const handleChangeDate = (from_service_date: Date, to_service_date: Date) => {
    setSearchPayload({
      ...searchPayload,
      from_service_date,
      to_service_date,
    });
  };

  const handleNewTripValue = (
    field_name: string,
    newValue: string | boolean | string[] | undefined
  ) => {
    if (newValue === "") {
      newValue = undefined;
    }
    const newTripValues = [
      ...searchPayload.trip_values.filter(
        (trip_value) => trip_value.field_name !== field_name
      ),
      {
        field_name,
        value: newValue,
      },
    ];
    setSearchPayload({
      ...searchPayload,
      trip_values: newTripValues,
    });
  };

  const handleSearch = async () => {
    const operator_short_name = selectedOperator.value;
    const searchJson = JSON.parse(JSON.stringify(searchPayload));

    // const formattedTripValues = search_payload.trip_values
    //   .filter(
    //     (x) =>
    //       x.value !== undefined && (!Array.isArray(x.value) || x.value.length !== 0)
    //   )
    //   .map((tripValue) => ({
    //     ...tripValue,
    //     value: Array.isArray(tripValue.value)
    //       ? tripValue.value.map((x) => x.value)
    //       : tripValue.value,
    //   }));
    const formattedPayload = {
      search_type: searchResultsTab,
      from_service_date: format(searchJson.from_service_date, "yyyy-MM-dd"),
      to_service_date: format(searchJson.to_service_date, "yyyy-MM-dd"),
    };
    setLoadingSearchResults(true);
    try {
      await searchOccurrences(operator_short_name, formattedPayload).then((res) => {
        setSearchResults(res);
        setLoadingSearchResults(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingSearchResults(false);
    }
  };

  const handleResetSearch = () => {
    setSelectedOperator("");
    setSearchPayload(defaultOccurrenceSearchPayload);
    setLoadingSearchResults(false);
    setSearchResults({});
  };

  const handleSetSeachResultsTab = (tab) => {
    setSearchResultsTab(tab);
    handleResetSearch();
  };

  const handleChangePage = (pageNumber, resultsPerPage) => {
    console.log(pageNumber);
    setSearchPayload({
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
  };

  const handleChangeSort = (sort, sort_by) => {
    setSearchPayload({
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
  };

  return (
    <div>
      <div className="page-header bg-info lg:flex justify-between px-4 py-5">
        <div className="md:flex gap-4 items-center prose text-white">
          <h1 className="flex gap-3 items-center m-0 text-2xl lg:text-4xl text-white">
            Manage Occurrences
          </h1>
        </div>
        <div className="breadcrumbs text-white hidden md:block">
          <ul>
            <li className="text-white/50">Manage Occurrences</li>
          </ul>
        </div>
      </div>

      <div className="drawer drawer-end w-auto">
        <input
          id="delays-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={delaysDrawerIsOpen}
        />
        <input
          id="unusual-occurrences-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={unusualOccurrencesDrawerIsOpen}
        />

        {delaysDrawerIsOpen && (
          <DelaysDrawer
            delaysDrawerIsOpen={delaysDrawerIsOpen}
            setDelaysDrawerIsOpen={setDelaysDrawerIsOpen}
          />
        )}
        {unusualOccurrencesDrawerIsOpen && (
          <UnusualOccurrencesDrawer
            unusualOccurrencesDrawerIsOpen={unusualOccurrencesDrawerIsOpen}
            setUnusualOccurrencesDrawerIsOpen={setUnusualOccurrencesDrawerIsOpen}
          />
        )}
      </div>

      <div className="p-5">
        <div className="w-full">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <div className="card-body gap-4">
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">
                    Operator<span className="text-error"> *</span>
                  </label>
                </div>
                <SingleSelect
                  placeholder={"Select operator..."}
                  value={selectedOperator}
                  isLoading={operators.isLoading}
                  handleOnChange={(o) => setSelectedOperator(o)}
                  options={
                    operators &&
                    Array.isArray(operators?.data) &&
                    operators?.data?.map((operator) => {
                      return { value: operator.short_name, label: operator.name };
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">
                    Service Date<span className="text-error"> *</span>
                  </label>
                </div>
                <DateRangePicker
                  startDate={searchPayload.from_service_date}
                  endDate={searchPayload.to_service_date}
                  handleChangeDate={handleChangeDate}
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Responsible Party</label>
                </div>
                <SingleSelect
                  placeholder={"Select"}
                  value={searchPayload.responsible_party_id}
                  isLoading={responsibleParties.isLoading}
                  handleOnChange={(party) => {
                    console.log("🚀 ~ ManageOccurrences ~ party:", party);

                    setSearchPayload({
                      ...searchPayload,
                      responsible_party_id: party,
                    });
                  }}
                  options={
                    responsibleParties &&
                    Array.isArray(responsibleParties?.data) &&
                    responsibleParties?.data?.map((party) => {
                      return { value: party.id, label: party.name };
                    })
                  }
                />
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Occurrence</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <SingleSelect
                    placeholder={"Cause"}
                    value={searchPayload.status}
                    handleOnChange={(status) =>
                      setSearchPayload({ ...searchPayload, status: status })
                    }
                    options={TRIP_STATUS_OPTIONS.map((option) => {
                      return { value: option.value, label: option.label };
                    })}
                  />
                  <SingleSelect
                    placeholder={"Waiver Status"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                  Primary
                  <Checkbox />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Trip</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <TextField
                    className="w-20"
                    placeholder={"Trip"}
                    value={searchPayload.trip_name}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        trip_name: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Assets</label>
                </div>
                <SingleSelect
                  placeholder={"Linear Asset"}
                  value={searchPayload.schedule_type}
                  isLoading={scheduleTypes.isLoading}
                  handleOnChange={(schedule_type) =>
                    setSearchPayload({ ...searchPayload, schedule_type })
                  }
                  options={
                    scheduleTypes &&
                    Array.isArray(scheduleTypes?.data) &&
                    scheduleTypes?.data?.map((schedule) => {
                      return { value: schedule.id, label: schedule.abbreviation };
                    })
                  }
                />
                <div className="join">
                  <SingleSelect
                    placeholder={"Stationary Asset Type"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                  <SingleSelect
                    placeholder={"Stationary Asset"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                </div>
                <div className="join">
                  <TextField
                    className="join-item w-full"
                    placeholder={"Carrier Code"}
                    value={searchPayload.carrier_code}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        carrier_code: event.target.value,
                      })
                    }
                  />
                  <TextField
                    className="join-item w-full"
                    placeholder={"Serial #"}
                    value={searchPayload.serial_number}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        serial_number: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Location</label>
                </div>
                <SingleSelect
                  placeholder={"Select"}
                  value={searchPayload.schedule_type}
                  isLoading={scheduleTypes.isLoading}
                  handleOnChange={(schedule_type) =>
                    setSearchPayload({ ...searchPayload, schedule_type })
                  }
                  options={
                    scheduleTypes &&
                    Array.isArray(scheduleTypes?.data) &&
                    scheduleTypes?.data?.map((schedule) => {
                      return { value: schedule.id, label: schedule.abbreviation };
                    })
                  }
                />
              </div>
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="collapse collapse-arrow">
              <input type="checkbox" />
              <h3 className="collapse-title px-0 m-0">Advanced Filters</h3>
              <div className="collapse-content">
                MMBF N/A <Checkbox />
                Arbitration Review <Checkbox />
                Division <MultiSelect />
              </div>
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="card-footer px-4 py-2">
              <div className="flex gap-3 items-center">
                <button
                  className="btn btn-ghost text-error flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator || loadingSearchResults}
                  onClick={() => handleResetSearch()}
                >
                  <FontAwesomeIcon icon={faRedo} />
                  Reset
                </button>
                <button
                  className="btn btn-primary flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator || loadingSearchResults}
                  onClick={() => handleSearch()}
                >
                  Search
                  {loadingSearchResults && <LoadingSpinner />}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <ManageOccurrencesTable
              data={searchResults.results}
              operator_trip_fields={operatorTripFields.data}
              selectedOperator={selectedOperator}
              sort={searchPayload.sort}
              sort_by={searchPayload.sort_by}
              handleChangeSort={handleChangeSort}
              searchResultsTab={searchResultsTab}
              handleSetSearchResultsTab={handleSetSeachResultsTab}
              setDelaysDrawerIsOpen={setDelaysDrawerIsOpen}
              setUnusualOccurrencesDrawerIsOpen={setUnusualOccurrencesDrawerIsOpen}
            />
            {searchResults?.results?.length > 0 && (
              <Pagination
                searchResults={searchResults}
                handleChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
