import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCheck,
  // faArrowLeftToLine,
  // faArrowRightFromLine,
  faTimes,
  faSlidersH,
} from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "../SingleSelect";
import { useNavigate } from "react-router-dom";
import { ManageColumns } from "../../utils/tableFunctions";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const OPTIONS = ["Test option 1", "Test option 2", "Test option 3"];

const COLUMNS = [
  { name: "service_date", display_name: "Date", visible: true },
  { name: "trip_name", display_name: "Trip Name", visible: true },
  {
    name: "origination_time",
    display_name: "Origination Time",
    visible: true,
  },
  { name: "mins_late", display_name: "Mins. Late", visible: true },
  { name: "vehicle_formation", display_name: "Formation", visible: true },
  { name: "occurrences", display_name: "Occurrences", visible: true },
  { name: "signed_off", display_name: "Signed Off", visible: true },
  { name: "warnings", display_name: "Warning", visible: true },
];

const excludedColumns = [
  "mins_late",
  "vehicle_formation",
  "occurrences",
  "signed_off",
  "warnings",
];

// const statusColors = {
//   "ON TIME": "success",
//   NONE: "error",
// };

function TableRow({ trip, columns, operator, visibleTableColumns }) {
  const navigate = useNavigate();
  const {
    service_date,
    trip_name,
    origination_time,
    mins_late,
    vehicle_formation,
    occurrences,
    signed_off,
    warnings,
    trip_values,
  } = trip;
  const urlFormattedDate = format(service_date.replace(/-/g, "/"), "yyyy-MM-dd");

  const handleRowClick = () => {
    const navigateTo = `/trips/operator/${operator.value}/operator_service_day/${urlFormattedDate}/trip/${trip_name}`;

    navigate(navigateTo);
  };

  // const TripStatus = ({ trip_status }) => {
  //   const statusColor = statusColors[trip_status] || "";

  //   return (
  //     <div className={`badge badge-sm badge-${statusColor} whitespace-nowrap`}>
  //       {trip_status}
  //     </div>
  //   );
  // };

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const dynamicColumns = [...visibleTableColumns].filter(
    (column) => !COLUMNS.some((staticColumn) => column.name === staticColumn.name)
  );

  return (
    <tr className="hover:bg-[#E5F6F9] hover:cursor-pointer" onClick={handleRowClick}>
      {columnIsVisible("service_date") && (
        <td>
          {service_date && format(service_date.replace(/-/g, "/"), "MM/dd/yyyy")}
        </td>
      )}
      {columnIsVisible("trip_name") && <td>{trip_name}</td>}
      {columnIsVisible("origination_time") && (
        <td>
          {origination_time && formatInTimeZone(origination_time, "UTC", "h:mm a")}
        </td>
      )}
      {columnIsVisible("mins_late") && <td>{mins_late}</td>}
      {columnIsVisible("vehicle_formation") && <td>{vehicle_formation}</td>}
      {columnIsVisible("occurrences") && <td>{occurrences}</td>}
      {columnIsVisible("signed_off") && (
        <td className="text-center">
          {signed_off ? (
            <FontAwesomeIcon icon={faCheck} className="text-primary" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="text-error" />
          )}
        </td>
      )}
      {columnIsVisible("warnings") && (
        <td>
          {warnings && (
            <span className="badge badge-error badge-sm rounded-full">
              {warnings}
            </span>
          )}
        </td>
      )}

      {/* dynamic  */}

      {dynamicColumns
        .filter((column) =>
          visibleTableColumns.some(
            (visibleColumn) =>
              visibleColumn?.name === column.name && visibleColumn?.visible === true
          )
        )
        ?.map((trip_value, key) => {
          const matchingTripValue = trip_values?.find(
            (value) => value?.name === trip_value?.name
          );

          if (
            typeof matchingTripValue?.value === "boolean" ||
            matchingTripValue?.value === "true" ||
            matchingTripValue?.value === "false"
          ) {
            return (
              <td key={key} className="text-center">
                {typeof matchingTripValue?.value === "boolean" ||
                  (matchingTripValue?.value === "true" ? (
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-error" />
                  ))}
              </td>
            );
          } else if (matchingTripValue?.label === "Direction") {
            return (
              <td key={key} className="flex gap-2 items-center">
                {matchingTripValue?.value}
              </td>
            );
          } else {
            return <td key={key}>{matchingTripValue?.value}</td>;
          }
        })}
    </tr>
  );
}

function TableRowMobile({ trip, operator }) {
  const navigate = useNavigate();
  const {
    service_date,
    trip_name,
    origination_time,
    mins_late,
    formation,
    occurrences,
    warnings,
    trip_values,
  } = trip;
  const urlFormattedDate = format(service_date.replace(/-/g, "/"), "yyyy-MM-dd");

  const handleRowClick = () => {
    const navigateTo = `/trips/operator/${operator.value}/operator_service_day/${urlFormattedDate}/trip/${trip_name}`;

    navigate(navigateTo);
  };

  // const TripStatus = ({ trip_status }) => {
  //   const statusColor = statusColors[trip_status] || "";

  //   return (
  //     <div className={`badge badge-sm badge-${statusColor} whitespace-nowrap`}>
  //       {trip_status}
  //     </div>
  //   );
  // };

  return (
    <div
      className="px-4 py-2 border-b hover:bg-[#E5F6F9] hover:cursor-pointer"
      onClick={handleRowClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center font-bold">
          {trip_name}
          {warnings && (
            <span className="badge badge-error badge-sm rounded-full">
              {warnings}
            </span>
          )}
        </div>
        {/* <TripStatus trip_status={trip_status} /> */}
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>
          {service_date && format(service_date.replace(/-/g, "/"), "MM/dd/yyyy")}{" "}
          {origination_time && formatInTimeZone(origination_time, "UTC", "h:mm a")}
        </div>
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>Route Here</div>
        {trip_values?.map((trip_value, key) => {
          if (trip_value.label === "Direction") {
            return <div>{trip_value.value}</div>;
          }
        })}
      </div>
      <div className="flex justify-between my-2 text-sm">
        <div>+ {mins_late} mins</div>
        <div>{formation}</div>
        <div>{occurrences} delays</div>
      </div>
    </div>
  );
}

const defineDynamicColumns = (trip_values: []) => {
  const dynamicColumns: { name: string; display_name: string; visible: boolean }[] =
    [];

  for (const trip_value of trip_values) {
    if (!dynamicColumns.some((col) => col.name === trip_value.name)) {
      dynamicColumns.push({
        name: trip_value.name,
        display_name: trip_value.label,
        visible: true,
      });
    }
  }

  return dynamicColumns;
};

export default function TripSearchTable({
  data,
  selectedOperator,
  sort,
  sort_by,
  handleChangeSort,
  tableRef,
}) {
  const [visibleTableColumns, setVisibleTableColumns] = useState(COLUMNS);
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    if (data) {
      const newColumns = data[0].trip_values
        ? defineDynamicColumns(data[0].trip_values)
        : [];
      setVisibleTableColumns([...COLUMNS, ...newColumns]);
    } else {
      setVisibleTableColumns(COLUMNS);
    }
  }, [data]);

  return (
    <>
      <div className="card-header py-2 px-4 border-b" ref={tableRef}>
        <div className="flex justify-between items-center">
          <h3 className="m-0">Search Results</h3>
          <div className="flex gap-3">
            <button
              onClick={() => setDisplayFilters(!displayFilters)}
              className="btn btn-sm btn-primary btn-outline"
            >
              Filters <FontAwesomeIcon icon={faSlidersH} />
            </button>
            <ManageColumns
              columns={visibleTableColumns}
              setVisibleTableColumns={setVisibleTableColumns}
            />
          </div>
        </div>
        {displayFilters && (
          <div className="flex flex-col md:flex-row gap-3 justify-end py-2">
            <SingleSelect
              options={OPTIONS}
              placeholder={"Penalties and Incentives"}
            />
            <SingleSelect options={OPTIONS} placeholder={"Data Integrity"} />
            <SingleSelect options={OPTIONS} placeholder={"Delay Management"} />
          </div>
        )}
      </div>

      <div className="mobile-results md:hidden">
        {data?.map((trip, id) => {
          return <TableRowMobile trip={trip} key={id} operator={selectedOperator} />;
        })}
      </div>

      <div className="desktop-results hidden md:block overflow-x-auto">
        <table className="table m-0 lg:text-base">
          {/* head */}
          <thead>
            <tr>
              {visibleTableColumns.map((column, id) => {
                const isSortable = !excludedColumns.includes(column.name);
                if (column.visible) {
                  const isDynamicSort = !COLUMNS.some(
                    (staticColumn) => staticColumn.name === column.name
                  );
                  return (
                    <th
                      className={
                        isSortable ? "hover:bg-[#E5F6F9] hover:cursor-pointer" : ""
                      }
                      key={id}
                      onClick={
                        isSortable
                          ? () =>
                              handleChangeSort(
                                `${sort === "DESC" ? "ASC" : "DESC"}`,
                                column.name,
                                isDynamicSort
                              )
                          : undefined
                      }
                    >
                      <div className="flex gap-2 items-center">
                        {column.display_name}
                        {data && column.name === sort_by && (
                          <div>
                            <FontAwesomeIcon
                              icon={sort === "DESC" ? faArrowUp : faArrowDown}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((trip, id) => {
              return (
                <TableRow
                  trip={trip}
                  key={id}
                  columns={visibleTableColumns}
                  operator={selectedOperator}
                  visibleTableColumns={visibleTableColumns}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hello!</h3>
          <p className="py-4">Press ESC key or click the button below to close</p>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}
