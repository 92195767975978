import SingleSelect from "../../SingleSelect";

const LocationField = ({
  location,
  occurrencePayload,
  setOccurrencePayload,
  trip,
  handleFieldChange,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">
        Location
        {location === "REQUIRED" && <span className="text-error"> *</span>}
      </span>
    </label>
    <SingleSelect
      placeholder="Pick one"
      value={occurrencePayload.location}
      handleOnChange={(location) => handleFieldChange("location", location)}
      options={
        Array.isArray(trip?.trip_locations) &&
        trip?.trip_locations?.map((location) => {
          return {
            label: location.location.name,
            value: location.location.id,
          };
        })
      }
    />
  </div>
);

export default LocationField;
