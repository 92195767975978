import { formatInTimeZone } from "date-fns-tz"; // Adjust the import as necessary

interface LinkedTripOccurrenceCardProps {
  linkedTripOccurrence: any;
  isEditMode: boolean;
}

const LinkedTripOccurrenceCard: React.FC<LinkedTripOccurrenceCardProps> = ({
  linkedTripOccurrence,
  isEditMode,
}) => {
  let name;
  if (isEditMode) {
    name =
      linkedTripOccurrence?.parent_occurrence?.title ??
      linkedTripOccurrence?.parent_trip_occurrence?.comments;
  } else {
    name =
      linkedTripOccurrence?.parent_occurrence?.name ??
      linkedTripOccurrence?.parent_trip_occurrence?.label;
  }

  return (
    <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
      <div className="flex justify-between gap-10">
        <div className="flex gap-5">
          <div>{name}</div>
        </div>
      </div>
    </div>
  );
};

export default LinkedTripOccurrenceCard;
