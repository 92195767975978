import { formatUnderscoreCasing } from "../../../utils/helperFunctions";

export default function TableRowMobile({ trip_occurrence, index }) {
  const {
    from_location: location,
    delay_minutes,
    operator_trip_occurrence_cause,
    waiver_status,
  } = trip_occurrence || {};

  // const { name } = operator_trip_occurrence_cause?.trip_occurrence_cause;

  const statusColors = {
    approved: "success",
    requested: "accent",
    denied: "error",
  };

  const WaiverStatus = ({ waiver_status }) => {
    const statusColor = statusColors[waiver_status] || "";

    return (
      <div className={`badge badge-sm badge-${statusColor}`}>
        {formatUnderscoreCasing(waiver_status)}
      </div>
    );
  };

  return (
    <div className="px-4 py-2 border-b">
      <div className="flex justify-between items-center">
        <div className="font-bold">{delay_minutes} Mins</div>
        <WaiverStatus waiver_status={waiver_status} />
      </div>
      <div className="text-sm">
        {"name"} at {location?.name}
      </div>
    </div>
  );
}
