//based on TRMS DB Design, this component's JS true/false boolean will need to be converted
//to a string "TRUE" or "FALSE" before being sent to the backend if used in a form

export default function Checkbox({
  handleOnChange,
  value,
  toggle,
  defaultChecked,
  disabled,
  checked,
  string,
}) {
  const handleValue = (value) => {
    if (string) {
      if (value === "TRUE") {
        return true;
      } else {
        return false;
      }
    } else {
      return value;
    }
  };

  return (
    <input
      checked={handleValue(value)}
      disabled={disabled}
      defaultChecked={defaultChecked}
      type="checkbox"
      value={handleValue(value)}
      className={
        toggle
          ? "toggle toggle-primary toggle-sm lg:toggle-md rounded-full"
          : "checkbox checkbox-primary checkbox-xs"
      }
      onChange={(event) => {
        if (string) {
          if (event.target.checked === true) {
            handleOnChange("TRUE");
          } else {
            handleOnChange("FALSE");
          }
        } else {
          handleOnChange(event.target.checked);
        }
      }}
    />
  );
}
