import { useState } from "react";
import LoadingSpinner from "../../icons/LoadingSpinner";
import SingleSelect from "../SingleSelect";
import TextField from "../TextField";
import TextArea from "../TextArea";
import DateTimePicker from "../DateTimePicker";
import { useQueries } from "@tanstack/react-query";
import useFetchFunctions from "../../services/trips";
import { FormatGroupLabel } from "../component-utils";
import DynamicOccurrenceCauseFormFields from "../trip-detail/trip-occurrences/DynamicOccurrenceCauseFormFields";
import DynamicFormFields from "../trip-detail/trip-occurrences/DynamicFormFields";

const defaultAddOccurrencePayload = {
  title: "",
  start: "",
  end: "",
  occurrence_cause: "",
  responsible_party: "",
  narrative: "",
};

const formatStationaryAssets = (stationaryAssets) => {
  const formattedArray = [];

  // Process locations
  stationaryAssets?.data?.locations?.forEach((location) => {
    const { id, name, stationary_assets } = location;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: name, assets: stationary_assets });
    }
  });

  // Process location_links
  stationaryAssets?.data?.location_links?.forEach((link) => {
    const { id, group, stationary_assets } = link;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: group, assets: stationary_assets });
    }
  });

  return { data: formattedArray, isLoading: stationaryAssets.isLoading };
};

export default function AddOccurrence({
  setAddOccurrenceDrawerIsOpen,
  addOccurrenceDrawerIsOpen,
  isAuthenticated,
}) {
  const [addOccurrencePayload, setAddOccurrencePayload] = useState(
    defaultAddOccurrencePayload
  );
  const [originalValues, setOriginalValues] = useState({});
  const [occurrenceToBeEdited, setOccurrenceToBeEdited] = useState({});
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState("");
  const [searchingVehicle, setSearchingVehicle] = useState(false);

  const {
    fetchOperators,
    fetchOperatorOccurrenceCauses,
    fetchResponsibleParties,
    postOccurrence,
    fetchLinearAssetsByTransportationMode,
    fetchStationaryAssetsByLinearAsset,
    fetchLocationsByLinearAsset,
  } = useFetchFunctions();

  console.log("🚀 ~ addOccurrencePayload:", addOccurrencePayload);
  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_occurrence_causes", selectedOperator?.value],
        queryFn: () =>
          fetchOperatorOccurrenceCauses(selectedOperator?.value?.short_name),
        enabled: !!selectedOperator?.value?.short_name,
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["linear_assets", selectedOperator?.value?.short_name],
        queryFn: () =>
          fetchLinearAssetsByTransportationMode(
            selectedOperator?.value?.transportation_mode?.abbreviation
          ),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator?.value?.short_name,
      },
      {
        queryKey: [
          "stationary_assets",
          addOccurrencePayload?.linear_asset_id?.value?.asset_id,
        ],
        queryFn: () =>
          fetchStationaryAssetsByLinearAsset(
            addOccurrencePayload?.linear_asset_id?.value?.id,
            addOccurrencePayload?.occurrence_cause?.value?.stationary_asset_type
          ),
        staleTime: 0,
        enabled:
          !!addOccurrencePayload?.linear_asset_id?.value?.id &&
          !!addOccurrencePayload?.occurrence_cause?.value?.stationary_asset &&
          (addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
            "OPTIONAL" ||
            addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
              "REQUIRED"),
      },
      {
        queryKey: ["locations", addOccurrencePayload?.linear_asset_id?.value?.id],
        queryFn: () =>
          fetchLocationsByLinearAsset(
            addOccurrencePayload?.linear_asset_id?.value?.id
          ),
        staleTime: 0,
        enabled:
          !!addOccurrencePayload?.linear_asset_id?.value?.id &&
          (addOccurrencePayload?.occurrence_cause?.value?.location === "OPTIONAL" ||
            addOccurrencePayload?.occurrence_cause?.value?.location === "REQUIRED"),
      },
    ],
  });

  const operators = results[0];
  const responsibleParties = results[1];
  const operatorOccurrenceCauses = results[2];
  const linearAssets = results[3];
  const stationaryAssets = results[4];
  const locations = results[5];

  const handleFieldChange = (field, value) => {
    const originalValue = originalValues[field];

    if (isEditMode) {
      if (value !== originalValue) {
        setOccurrenceToBeEdited((payload) => ({
          ...payload,
          [field]: value,
        }));
      } else {
        const updatedEdited = { ...occurrenceToBeEdited };
        delete updatedEdited[field];
        setOccurrenceToBeEdited(updatedEdited);
      }
    } else {
      setAddOccurrencePayload((payload) => ({
        ...payload,
        [field]: value,
      }));
    }
  };

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        selectedOperator?.value?.short_name,
        vehicleSearchPayload.carrier_code,
        vehicleSearchPayload.serial_number
      ).then((res) => {
        if (!res.error) {
          // if (isEditMode) {
          //   setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
          //     ...prevOccurrenceToBeEdited,
          //     vehicle: res,
          //   }));
          // } else {
          setAddOccurrencePayload((prevOccurrencePayload) => ({
            ...prevOccurrencePayload,
            vehicle: res,
          }));
          // }
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handleAddOccurrence = async () => {
    const addOccurrenceJson = JSON.parse(JSON.stringify(addOccurrencePayload));
    console.log("add occurrence");
    console.log(addOccurrenceJson);

    const formattedPayload = {
      primary: true,
      start_datetime: addOccurrenceJson?.start,
      end_datetime: addOccurrenceJson?.end,
      title: addOccurrenceJson?.title,
      field_report: false,
      linear_asset_start:
        addOccurrenceJson?.linear_asset_id?.value?.start_measurement,
      linear_asset_end: addOccurrenceJson?.linear_asset_id?.value?.end_measurement,
      description: addOccurrenceJson?.narrative,
      occurrence_cause_id: addOccurrenceJson?.occurrence_cause?.value?.id,
      responsible_party_id: addOccurrenceJson?.responsible_party?.value,
      location_id: addOccurrenceJson?.location?.value,
      linear_asset_id: addOccurrenceJson?.linear_asset_id?.value?.id,
      stationary_asset_id: addOccurrenceJson?.stationary_asset?.value.id,
      vehicle_id: addOccurrenceJson?.vehicle,
      values: [],
    };

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    console.log("cleaned payload");
    console.log(cleanedPayload);

    try {
      await postOccurrence(selectedOperator?.value?.short_name, cleanedPayload).then(
        (res) => {
          console.log(res);
          setAddOccurrenceDrawerIsOpen(false);
          setAddOccurrencePayload(defaultAddOccurrencePayload);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="create-occurrence-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Unusual Occurrence Details</h2>
        <label
          htmlFor="add-occurrence-drawer"
          onClick={() => {
            setAddOccurrenceDrawerIsOpen(false);
            setAddOccurrencePayload(defaultAddOccurrencePayload);
          }}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Operator <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={operators?.isLoading}
            isLoading={operators?.isLoading}
            value={selectedOperator}
            handleOnChange={(operator) => setSelectedOperator(operator)}
            options={
              operators?.data &&
              Array.isArray(operators?.data) &&
              operators.data?.map((operator) => {
                return { value: operator, label: operator.name };
              })
            }
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Title <span className="text-error">*</span>
            </span>
          </label>
          <TextField
            placeholder="Enter a title"
            value={addOccurrencePayload.title}
            handleOnChange={(title) =>
              handleFieldChange("title", title.target.value)
            }
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Start <span className="text-error">*</span>
            </span>
          </label>
          <DateTimePicker
            value={addOccurrencePayload.start}
            handleOnChange={(date) =>
              setAddOccurrencePayload({
                ...addOccurrencePayload,
                start: date,
              })
            }
            className="input input-bordered input-sm lg:input-md w-full"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              End <span className="text-error">*</span>
            </span>
          </label>
          <DateTimePicker
            value={addOccurrencePayload.end}
            handleOnChange={(date) =>
              setAddOccurrencePayload({
                ...addOccurrencePayload,
                end: date,
              })
            }
            className="input input-bordered input-sm lg:input-md w-full"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Occurrence Cause <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={operatorOccurrenceCauses?.isLoading || !selectedOperator}
            isLoading={operatorOccurrenceCauses?.isLoading}
            formatGroupLabel={FormatGroupLabel}
            value={addOccurrencePayload.occurrence_cause}
            handleOnChange={(occurrence_cause) =>
              handleFieldChange("occurrence_cause", {
                value: occurrence_cause.value.occurrence_cause,
                label: occurrence_cause.label,
              })
            }
            options={
              Array.isArray(operatorOccurrenceCauses?.data) &&
              Object.values(
                operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                  const type = item.occurrence_cause.type;
                  if (!acc[type]) {
                    acc[type] = {
                      label: type,
                      options: [],
                    };
                  }
                  acc[type].options.push({
                    value: item,
                    label: item.occurrence_cause.name,
                  });
                  return acc;
                }, {})
              )
            }
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Linear Asset <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={linearAssets?.isLoading || !selectedOperator}
            isLoading={linearAssets?.isLoading}
            value={addOccurrencePayload.linear_asset_id}
            handleOnChange={(asset) => handleFieldChange("linear_asset_id", asset)}
            options={
              linearAssets?.data &&
              Array.isArray(linearAssets?.data) &&
              linearAssets.data?.map((asset) => {
                return { value: asset, label: asset.name };
              })
            }
          />
        </div>

        {addOccurrencePayload?.occurrence_cause && (
          <DynamicOccurrenceCauseFormFields
            locations={locations}
            occurrencePayload={addOccurrencePayload}
            setOccurrencePayload={setAddOccurrencePayload}
            stationaryAssets={
              stationaryAssets && formatStationaryAssets(stationaryAssets)
            }
            handleFieldChange={handleFieldChange}
            vehicleSearchPayload={vehicleSearchPayload}
            setVehicleSearchPayload={setVehicleSearchPayload}
            searchingVehicle={searchingVehicle}
            handleVehicleLookUp={handleVehicleLookUp}
            occurrenceCause={
              addOccurrencePayload?.occurrence_cause &&
              addOccurrencePayload?.occurrence_cause
            }
            hideLinearAssetField={true}
            linearAssetSelectionRequired={true}
          />
        )}

        {/* <div className="form-control">
          <label className="label">
            <span className="label-text">
              Stationary Asset <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={
              stationaryAssets?.isLoading ||
              !addOccurrencePayload?.linear_asset_id?.value?.id ||
              !addOccurrencePayload?.occurrence_cause ||
              addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
                "NOT SHOWN"
            }
            isLoading={stationaryAssets?.isLoading}
            value={addOccurrencePayload?.stationary_asset_id}
            handleOnChange={(asset) =>
              handleFieldChange("stationary_asset_id", asset)
            }
            formatGroupLabel={FormatGroupLabel}
            options={
              stationaryAssets?.data &&
              Array.isArray(stationaryAssets?.data?.locations) &&
              stationaryAssets.data.locations.map((location) => {
                return {
                  label: location.name,
                  options: location.stationary_assets.map((asset) => {
                    return { value: asset.id, label: asset.name };
                  }),
                };
              })
            }
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Location <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={
              stationaryAssets?.isLoading ||
              !addOccurrencePayload?.linear_asset_id?.value?.id ||
              !addOccurrencePayload?.occurrence_cause ||
              addOccurrencePayload?.occurrence_cause?.value?.location === "NOT SHOWN"
            }
            isLoading={stationaryAssets?.isLoading}
            value={addOccurrencePayload?.location}
            handleOnChange={(location) => handleFieldChange("location", location)}
            options={
              stationaryAssets?.data &&
              Array.isArray(stationaryAssets?.data?.locations) &&
              stationaryAssets.data.locations.map((location) => {
                return { value: location.id, label: location.name };
              })
            }
          />
        </div> */}

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Responsible Party <span className="text-error">*</span>
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isDisabled={responsibleParties?.isLoading}
            isLoading={responsibleParties?.isLoading}
            value={addOccurrencePayload.responsible_party}
            handleOnChange={(responsible_party) =>
              handleFieldChange("responsible_party", responsible_party)
            }
            options={
              responsibleParties?.data &&
              Array.isArray(responsibleParties?.data) &&
              responsibleParties.data?.map((party) => {
                return { value: party.id, label: party.name };
              })
            }
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Narrative
              <span className="text-error"> *</span>
            </span>
          </label>
          <TextArea
            value={addOccurrencePayload.narrative}
            handleOnChange={(event) =>
              handleFieldChange("narrative", event.target.value)
            }
          />
          <label className="label">
            <span></span>
            <span className="label-text-alt">
              {addOccurrencePayload.narrative.length}/280
            </span>
          </label>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setAddOccurrenceDrawerIsOpen(false);
              setAddOccurrencePayload(defaultAddOccurrencePayload);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleAddOccurrence()}
            // disabled={true}
          >
            Save
            {/* {creatingTrip && <LoadingSpinner />} */}
          </button>
        </div>
      </div>
    </div>
  );
}
