export const STATIC_CAUSES = [{ id: 1, name: "Operator" }];

export const COLUMNS = [
  { name: "location", display_name: "Location", visible: true },
  { name: "delay_minutes", display_name: "Min", visible: true },
  { name: "primary_trip_occurrence", display_name: "Primary", visible: true },
  { name: "cause", display_name: "Cause", visible: true },
  { name: "responsible_party", display_name: "Responsible Party", visible: true },
  { name: "comments", display_name: "Comment", visible: true },
  { name: "waiver_status", display_name: "Waiver", visible: true },
];

export const defaultOccurrencePayload = {
  primary: false,
  delay_minutes: "",
  trip_status_attribution: "",
  reported_only: false,
  waiver_status: "",
  comments: "",
  trip_id: "",
  operator_trip_occurrence_cause_id: "",
  vehicle: "",
  responsible_party_id: "",
  responsible_operator_id: "",
  values: [],
  narrative: "",
  mmbfna: false,
  conflicting_train: "",
  stationary_asset: "",
  location: "",
  start: "",
  end: "",
  title: "",
};

export const statusColors = {
  approved: "success",
  requested: "accent",
  denied: "error",
};

export const defaultLinkedOccurrencePayload = {
  parent_occurrence: "",
  parent_trip_occurrence: "",
  delay_minutes: "",
  waiver_status: "",
  arbitration_review: false,
  comments: "",
  values: [],
};
