import { apiUrl, createAuthHelpers } from "./api";
import { useMsal } from "@azure/msal-react";
import { TripSearchPayload } from "../types/trips";
import toast from "react-hot-toast";

export const useFetchFunctions = () => {
  const { instance: msalInstance } = useMsal();
  const { authHeaders } = createAuthHelpers(msalInstance);

  const fetchData = async (url, options = {}) => {
    const headers = await authHeaders();
    const response = await fetch(url, { headers, ...options });

    if (!response.ok) {
      let errorDetail = `Error: ${response.statusText}`;
      if (response.status === 403) {
        try {
          const errorResponse = await response.json();
          if (errorResponse.detail) {
            errorDetail = errorResponse.detail;
          }
        } catch (error) {
          console.error("Error parsing error response:", error);
        }
      }
      toast.error(errorDetail);
      throw new Error(errorDetail);
    }

    return response.json();
  };

  const handleFetchError = (error, errorMessage) => {
    console.error(errorMessage, error);
    toast.error(errorMessage);
    return { error: error.message };
  };

  return {
    fetchTripFields: () => fetchData(apiUrl + "/tripFields"),

    extendTrip: async (operator_short_name, service_date, trip_name, payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/extend`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error extending trip.");
      }
    },

    fetchAvailableReroutes: async (operator_short_name, service_date, trip_name) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/available_reroutes`;
      try {
        return await fetchData(url);
      } catch (error) {
        return handleFetchError(error, "Error fetching available reroutes.");
      }
    },

    postRerouteTrip: async (
      operator_short_name,
      service_date,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/reroute`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error rerouting trip.");
      }
    },

    fetchLocationsByTransportationMode: async (transportation_mode) => {
      const url = `${apiUrl}/locations?transportation_mode=${transportation_mode}`;
      try {
        return await fetchData(url);
      } catch (error) {
        return handleFetchError(error, "Error fetching locations.");
      }
    },

    fetchPaths: async (operator_short_name, origin, destination) => {
      const url = `${apiUrl}/operator/${operator_short_name}/pathfinding?origin=${origin}&destination=${destination}`;
      try {
        return await fetchData(url);
      } catch (error) {
        return handleFetchError(error, "Error fetching possible routes.");
      }
    },

    fetchAuditLog: async (operator_short_name, service_date, trip_name) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/auditlog`;
      try {
        return await fetchData(url);
      } catch (error) {
        return handleFetchError(error, "Error getting audit history.");
      }
    },

    signOffTrip: async (operator_short_name, service_date, trip_name, payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/signed_off`;
      try {
        return await fetchData(url, {
          method: "PATCH",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error signing off Trip.");
      }
    },

    resetTrip: async (operator_short_name, service_date, trip_name) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/reset`;
      try {
        return await fetchData(url, {
          method: "POST",
        });
      } catch (error) {
        return handleFetchError(error, "Error resetting Trip.");
      }
    },

    postTrip: async (operator_short_name, service_date, payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trips`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error creating Trip.");
      }
    },

    editTripLocations: async (
      operator_short_name,
      service_date,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/trip_locations`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error updating Trip Locations.");
      }
    },

    originateTripLocation: async (
      operator_short_name,
      service_date,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/originate`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error originating trip location.");
      }
    },

    terminateTripLocation: async (
      operator_short_name,
      service_date,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/terminate`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error terminating trip location.");
      }
    },

    fetchVehicleFormations: async (
      operator_short_name,
      carrier_code,
      serial_number,
      timestamp
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/vehicle_formations?carrier_code=${carrier_code}&serial_number=${serial_number}&timestamp=${timestamp}`;
      return fetchData(url);
    },

    assignVehicleFormation: async (
      payload,
      operator_short_name,
      date,
      trip_name
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${date}/trip/${trip_name}/assigned_vehicle_formation`;
      try {
        return await fetchData(url, {
          method: "PATCH",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error assigning Vehicle Formation.");
      }
    },

    fetchVehicleFormationFields: () => fetchData(apiUrl + "/vehicleFormationFields"),

    fetchOperators: () => fetchData(apiUrl + "/operators"),

    fetchOperatorTripFields: (operator_short_name) =>
      fetchData(`${apiUrl}/operator/${operator_short_name}/operator_trip_fields`),

    fetchOperatorTripLocationFields: (operator_short_name) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/operator_trip_location_fields`
      ),

    fetchResponsibleParties: () => fetchData(apiUrl + "/responsible_parties"),

    fetchOperatorOccurrenceCauses: (operator_short_name) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/operator_occurrence_causes`
      ),

    fetchOperatorTripOccurrenceFields: (operator_short_name) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/operator_trip_occurrence_fields`
      ),

    searchOccurrences: async (operator_short_name, search_payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/occurrences_search`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(search_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error searching occurrences.");
      }
    },

    searchLinkToExisting: async (search_payload) => {
      const url = `${apiUrl}/occurrences/link_to_existing_search`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(search_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error searching occurrences.");
      }
    },

    postOccurrence: async (operator_short_name, occurrence_payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/occurrences`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error posting occurrence.");
      }
    },

    postPrimaryTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_id,
      occurrence_payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_id}/occurrences`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error posting occurrence.");
      }
    },

    postTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_id,
      occurrence_payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_id}/trip_occurrences`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error posting occurrence.");
      }
    },

    editTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_id,
      occurrence_payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_id}/trip_occurrences`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error editing occurrence.");
      }
    },

    editResidualTripOccurrence: async (
      operator_short_name,
      occurrence_id,
      trip_occurrence_id,
      trip_occurrence_payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/occurrences/${occurrence_id}/trip_occurrences/${trip_occurrence_id}`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(trip_occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error updating trip occurrence.");
      }
    },

    editPrimaryTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_name,
      occurrence_payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/occurrences`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(occurrence_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error updating occurrence.");
      }
    },

    deleteTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_name,
      trip_occurrence_id
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/trip_occurrences/${trip_occurrence_id}`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "DELETE", headers });
        if (!response.ok) {
          const errorText = `Error deleting occurrence: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.text();
      } catch (error) {
        return handleFetchError(error, "Error deleting occurrence.");
      }
    },

    fetchStationaryAssets: async (
      operator_short_name,
      service_date,
      trip_name,
      stationary_asset_type
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/stationary_assets?stationary_asset_type=${stationary_asset_type}`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) {
          const errorText = `Error getting assets: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.json();
      } catch (error) {
        return handleFetchError(error, "Error getting assets.");
      }
    },

    fetchLocationsByLinearAsset: async (linear_asset_id) => {
      const url = `${apiUrl}/linear_asset/${linear_asset_id}/locations`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) {
          const errorText = `Error getting locations: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.json();
      } catch (error) {
        return handleFetchError(error, "Error getting locations.");
      }
    },

    fetchStationaryAssetsByLinearAsset: async (
      linear_asset_id,
      stationary_asset_type
    ) => {
      const url = `${apiUrl}/linear_asset/${linear_asset_id}/stationary_assets/${stationary_asset_type}`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) {
          const errorText = `Error getting assets: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.json();
      } catch (error) {
        return handleFetchError(error, "Error getting assets.");
      }
    },

    fetchLinearAssets: async (operator_short_name, service_date, trip_name) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/linear_assets`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) {
          const errorText = `Error getting assets: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.json();
      } catch (error) {
        return handleFetchError(error, "Error getting assets.");
      }
    },

    fetchLinearAssetsByTransportationMode: async (transportation_mode) => {
      const url = `${apiUrl}/linear_assets?transportation_mode=${transportation_mode}`;
      try {
        const headers = await authHeaders();
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) {
          const errorText = `Error getting assets: ${response.statusText}`;
          throw new Error(errorText);
        }
        return response.json();
      } catch (error) {
        return handleFetchError(error, "Error getting assets.");
      }
    },

    fetchOperatorVehicles: (operator_short_name) =>
      fetchData(`${apiUrl}/operator/${operator_short_name}/vehicles`),

    fetchVehicle: (operator_short_name, carrier_code, serial_number) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/vehicles/${carrier_code}/${serial_number}`
      ),

    fetchOccurrencesOperatorServiceDay: (operator_short_name, service_date) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/occurrences`
      ),

    postResidualTripOccurrence: async (
      operator_short_name,
      service_date,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/trip_occurrences`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error linking Trip Occurrence.");
      }
    },

    fetchTrip: (operator_short_name, service_date, trip_id) =>
      fetchData(
        `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_id}`
      ),

    fetchTrips: async (operator_short_name, search_payload) => {
      const url = `${apiUrl}/operator/${operator_short_name}/trip_search`;
      try {
        return await fetchData(url, {
          method: "POST",
          body: JSON.stringify(search_payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error fetching trips.");
      }
    },

    fetchScheduleTypes: () => fetchData(apiUrl + "/schedule_types"),

    editTripValues: async (
      service_date,
      operator_short_name,
      trip_name,
      payload
    ) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/trip_values`;
      try {
        return await fetchData(url, {
          method: "PUT",
          body: JSON.stringify(payload),
        });
      } catch (error) {
        return handleFetchError(error, "Error updating Trip Values.");
      }
    },

    cancelTrip: async (service_date, operator_short_name, trip_name) => {
      const url = `${apiUrl}/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip_name}/cancel`;
      try {
        return await fetchData(url, { method: "PUT" });
      } catch (error) {
        return handleFetchError(error, "Error canceling trip.");
      }
    },
  };
};

export default useFetchFunctions;
