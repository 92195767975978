import YesNoCheckbox from "../YesNoCheckbox";
import MultiSelect from "../MultiSelect";
import TextField from "../TextField";

export default function AdvancedSearchFilters({
  handleNewTripValue,
  searchPayload,
  operatorTripFields,
}) {
  return (
    <div className="max-sm:collapse">
      <input type="checkbox" className="min-h-0 sm:hidden" />
      <div className="collapse-title py-3 px-0 link text-center text-primary min-h-0 sm:hidden">
        Show Advanced Filters
      </div>
      <div className="collapse-content flex flex-col gap-4 p-0 sm:visible">
        <div className="divider m-0 h-px"></div>

        <div className="flex flex-col gap-4">
          <h3 className="m-0 leading-7">Advanced Filters</h3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
            {operatorTripFields?.data
              ?.filter((tripField) => tripField["trip_field"]["type"] === "BOOLEAN")
              .map((tripField) => (
                <div key={tripField["id"]}>
                  <YesNoCheckbox
                    key={tripField["id"]}
                    name={tripField["trip_field"]["label"]}
                    handleOnChange={(newValue: boolean) =>
                      handleNewTripValue(tripField["trip_field"]["name"], newValue)
                    }
                    value={
                      searchPayload?.trip_values?.find(
                        (trip_value) =>
                          trip_value.field_name === tripField["trip_field"]["name"]
                      )?.value
                    }
                  />
                </div>
              ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
            {operatorTripFields?.data
              ?.filter((tripField) => tripField["lookup_list_items"]?.length)
              .map((tripField) => (
                <div key={tripField["id"]}>
                  <div className="flex gap-4 items-center">
                    <div className="w-32">
                      <label className="font-bold">
                        {tripField["trip_field"]["label"]}
                      </label>
                    </div>

                    <MultiSelect
                      handleOnChange={(event) =>
                        handleNewTripValue(tripField["trip_field"]["name"], event)
                      }
                      value={
                        searchPayload?.trip_values?.find(
                          (trip_value) =>
                            trip_value.field_name === tripField["trip_field"]["name"]
                        )?.value
                      }
                      options={tripField["lookup_list_items"].map((item) => {
                        return { value: item, label: item };
                      })}
                    />
                  </div>
                </div>
              ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
            {operatorTripFields?.data
              ?.filter(
                (tripField) =>
                  !tripField["lookup_list_items"]?.length &&
                  tripField["trip_field"]["type"] === "TEXT"
              )
              .map((tripField) => (
                <div key={tripField["id"]}>
                  <div className="flex gap-4 items-center">
                    <div className="w-32">
                      <label className="font-bold">
                        {tripField["trip_field"]["label"]}
                      </label>
                    </div>

                    <TextField
                      placeholder={tripField["trip_field"]["label"]}
                      handleOnChange={(event) =>
                        handleNewTripValue(
                          tripField["trip_field"]["name"],
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
